import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMutation } from 'urql';

import { Schema } from '@serenityapp/domain';
import { Location, Building, LocationGroup, Unit } from '@serenityapp/api-graphql';
import { snackAdd } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

import { hasLocationEdges, countLocationsByKind } from '../utils';
import { listSx, listItemSx, boldTextSx } from './styles';

type DeleteLocationDialogProps = {
  dataTestId?: string;
  location?: Location.LocationApi.Location;
  isOpen?: boolean;
  onClose: () => void;
  onActionSuccess?: () => void;
};

const DeleteLocationDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  location,
  onActionSuccess,
}: DeleteLocationDialogProps) => {
  const makeTestId = useMakeTestId('DeleteLocationDialog', dataTestId);
  const deleteLocationDialog = makeTestId('');
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const [, removeBuilding] = useMutation<
    Schema.Building.Remove.Output,
    Schema.Building.Remove.Variables
  >(Building.BuildingApi.buildingRemoveMutation);

  const [, removeUnit] = useMutation<Schema.Unit.Remove.Output, Schema.Unit.Remove.Variables>(
    Unit.Api.unitRemoveMutation,
  );

  const [, removeLocationGroup] = useMutation<
    Schema.LocationGroup.Update.Output,
    Schema.LocationGroup.Update.Variables
  >(LocationGroup.Api.locationGroupRemoveMutation);

  const handleSuccess = () => {
    onActionSuccess?.();
    setIsLoading(false);
    onClose();
    dispatch(snackAdd({ message: `Location was successfully deleted`, type: 'success' }));
  };

  const handleError = () => {
    onClose();
    setIsLoading(false);
    dispatch(snackAdd({ message: `Error deleting location`, type: 'error' }));
  };

  const handleDeleteLocation = () => {
    setIsLoading(true);
    if (!location) return;
    const locationType = location?.kind;

    switch (locationType) {
      case 'Building': {
        removeBuilding({
          input: {
            id: location.id,
          },
        }).then((result) => (result.error ? handleError() : handleSuccess()));
        break;
      }
      case 'Unit':
        removeUnit({
          input: {
            id: location.id,
          },
        }).then((result) => (result.error ? handleError() : handleSuccess()));
        break;

      case 'LocationGroup':
        removeLocationGroup({
          input: {
            id: location.id,
          },
        }).then((result) => (result.error ? handleError() : handleSuccess()));
        break;

      default:
        break;
    }
  };

  const descendantLocationCounts =
    location && hasLocationEdges(location) ? countLocationsByKind(location.locations?.edges) : {};

  const descendantLocations = Object.entries(descendantLocationCounts).map(
    ([kind, count]) => `${count} ${kind}${count > 1 ? 's' : ''}`,
  );

  const hasDescendantLocations = descendantLocations.length !== 0;

  const confirmationLabel = hasDescendantLocations
    ? 'Yes, I want to delete this location and its associated locations'
    : 'Yes, I want to delete this location';

  return (
    <Dialog
      fullWidth
      data-testid={deleteLocationDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Delete location</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the {location?.displayName?.toLowerCase()}{' '}
          <Typography component="span" sx={boldTextSx} variant="subtitle1">
            {location?.name}
          </Typography>
          ?{' '}
          {hasDescendantLocations &&
            'This action will also remove the following associated locations:'}
        </Typography>
        {hasDescendantLocations && (
          <List dense sx={listSx}>
            {descendantLocations.map((item, index) => (
              <ListItem key={index} sx={[boldTextSx, listItemSx]}>
                {item}
              </ListItem>
            ))}
          </List>
        )}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleConfirmCheckbox} />}
            label={confirmationLabel}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('delete-location')}
          disabled={!checked}
          loading={isLoading}
          variant="contained"
          onClick={handleDeleteLocation}
        >
          Delete location
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteLocationDialog;
