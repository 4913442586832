import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import {
  BasicTable,
  BasicTableHeadCellProps,
  AdministrationHeader,
  View,
  useMakeTestId,
  SearchBar,
} from '@serenityapp/components-react-web';
import {
  getConversationsLoadingState,
  getFilteredSortedChannels,
  RootState,
} from '@serenityapp/redux-store';
import { ConversationProps } from '@serenityapp/domain';
import { useAdminBreadcrumbs } from '../hooks';

const ChannelsPage = () => {
  const title = 'Channels Management';
  const makeTestId = useMakeTestId('ChannelsPage');
  const mainTestId = makeTestId('');

  const [searchKey, setSearchKey] = useState('');

  const navigate = useNavigate();

  const goToResourceDetails = (id: string) => navigate(id);
  const goToResourceCreate = () => navigate('create');

  const channels: Array<ConversationProps> = useSelector((state: RootState) =>
    getFilteredSortedChannels(state, searchKey),
  );

  const isLoading = useSelector(getConversationsLoadingState);

  const shouldShowSkeleton = isLoading && channels.length === 0;

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'name', label: 'Name' },
    { id: 'subsubtype', label: 'Type' },
    { id: 'description', label: 'Description', sort: false, hide: 'smDown' },
  ];

  const crumbs = useAdminBreadcrumbs(title);

  const addButton = (
    <Button
      key="add-button"
      data-testid={mainTestId}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;channel
      </Box>
    </Button>
  );

  return (
    <View>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <Box>
          <SearchBar
            dataTestId={makeTestId('Filter')}
            sx={searchBarSx}
            value={searchKey}
            onSearchKeyChange={setSearchKey}
          />
        </Box>
        <BasicTable
          dataTestId={mainTestId}
          handleRowClick={goToResourceDetails}
          headCells={headCells}
          rows={channels}
          showSkeleton={shouldShowSkeleton}
        />
      </View.Content>
      <Outlet />
    </View>
  );
};

const searchBarSx = {
  px: 2,
  pb: 1,
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default ChannelsPage;
