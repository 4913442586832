import { useMemo } from 'react';
import { useQuery, UseQueryArgs } from 'urql';

import { Schema } from '@serenityapp/domain';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import {
  organizationLocationGroupsQuery,
  OrganizationLocationGroupsResult,
} from '@serenityapp/api-graphql';

import { useCurrentUser } from '../../../common/hooks';

type UseLocationGroupsReturnType = {
  locationGroups?: Schema.Organization.LocationGroups.Connection;
  locationGroupOptions: SelectorItemProps[];
  locationGroupsFetching: boolean;
  locationGroupBuildingsSelectItems: SelectorItemProps[];
};

/**
 * This hook is used to fetch the location groups of the current organization,
 * transform them to select items and return the selected location group.
 *
 * @param selectedLocationGroupId
 */
const useLocationGroups = (selectedLocationGroupId?: string): UseLocationGroupsReturnType => {
  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId || '';

  const locationGroupsQueryOptions: UseQueryArgs<
    Schema.Organization.LocationGroups.Variables,
    OrganizationLocationGroupsResult
  > = useMemo(
    () => ({
      query: organizationLocationGroupsQuery,
      requestPolicy: 'cache-and-network',
      variables: { getInput: { id: organizationId } },
    }),
    [organizationId],
  );

  const [locationGroupsResult] = useQuery(locationGroupsQueryOptions);
  const { data, fetching } = locationGroupsResult;

  const locationGroups = data?.result?.locationGroups;

  // Transform location group data to items for select field
  const locationGroupOptions = useMemo(
    () =>
      locationGroups?.edges?.map((edge: Schema.Organization.LocationGroups.Edge) => ({
        id: edge.locationGroup.id,
        label: edge.locationGroup.name,
      })) || [],
    [locationGroups],
  );

  // Find the selected location group by its ID
  const selectedLocationGroup = useMemo(
    () =>
      selectedLocationGroupId
        ? locationGroups?.edges?.find(
            (edge: any) => edge.locationGroup.id === selectedLocationGroupId,
          )
        : undefined,
    [locationGroups, selectedLocationGroupId],
  );

  // Transform the selected location group's buildings for select field items
  const locationGroupBuildingsSelectItems = useMemo(
    () =>
      selectedLocationGroup?.locationGroup?.locations?.edges
        ? selectedLocationGroup.locationGroup.locations.edges
            .filter((edge: any) => edge.location.kind === 'Building')
            .map((item: any) => ({
              id: item.location.id,
              label: item.location.name,
            }))
        : [],
    [selectedLocationGroup],
  );

  return useMemo(
    () => ({
      locationGroups,
      locationGroupOptions,
      locationGroupsFetching: fetching,
      locationGroupBuildingsSelectItems,
    }),
    [locationGroups, locationGroupOptions, fetching, locationGroupBuildingsSelectItems],
  );
};

export default useLocationGroups;
