import { ZodError, ZodSchema } from 'zod';
import { FormikErrors, FormikValues } from 'formik';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { Schema } from '@serenityapp/domain';
import { ServiceLevel, ServiceLevelFn } from '@serenityapp/core';

import { Location } from '@serenityapp/api-graphql';

export const DEFAULT_LOCATIONS_PAGE_SIZE = 25;

type IndexedErrors = FormikErrors<any> & {
  [index: number]: string;
};

type FormikIndexedErrors = FormikErrors<FormikValues> & {
  [key: string]: string | IndexedErrors | FormikErrors<any> | undefined;
};

// Validate with Zod and handle nested errors
export const validateWithZod =
  (schema: ZodSchema<any>) =>
  (values?: FormikValues): FormikIndexedErrors => {
    const errors: FormikIndexedErrors = {};
    if (values) {
      try {
        schema.parse(values);
      } catch (e) {
        if (e instanceof ZodError) {
          e.errors.forEach((error) => {
            // Check if this error is for an array field and includes a field index
            if (typeof error.path[1] === 'number') {
              const arrayFieldName = error.path[0] as string;
              const fieldIndex = error.path[1] as number;
              const errorMessage = error.message as string;

              // Initialize the error object for the array if it doesn't exist
              if (!errors[arrayFieldName]) {
                errors[arrayFieldName] = {};
              }

              // Ensure the storage for this index is initialized as an object
              if (!(errors[arrayFieldName] as IndexedErrors)[fieldIndex]) {
                (errors[arrayFieldName] as IndexedErrors)[fieldIndex] = errorMessage;
              }
            } else {
              // Handle non-array fields
              const errorKey = error.path.join('.');
              errors[errorKey] = error.message;
            }
          });
        }
      }
    }
    return errors;
  };

export const locationTypes: SelectorItemProps[] = [
  { id: 'Building', label: 'Building' },
  { id: 'LocationGroup', label: 'Location Group' },
  { id: 'Unit', label: 'Unit' },
];

export const MULTI_PURPOSE_SERVICE_LEVEL = 'MULTI_PURPOSE';

export const getServiceLevelsFromUsers = (users: SelectorItemProps[] = []) => {
  return users.reduce((acc: ServiceLevel[], user: SelectorItemProps) => {
    const userServiceLevel = ServiceLevelFn.getFromString(user.subtitle);
    if (userServiceLevel && !acc.includes(userServiceLevel)) {
      return [...acc, userServiceLevel];
    }
    return acc;
  }, []);
};

type LocationsCount = Record<string, number>;

// Count the number of locations of each kind in a list of edges
// example: { Units: 2, Building: 1 }
export const countLocationsByKind = (
  edges?:
    | readonly Schema.Building.Locations.Edge[]
    | readonly Schema.LocationGroup.Locations.Edge[],
): LocationsCount => {
  if (!edges) {
    return {};
  }

  return edges.reduce<LocationsCount>((count, item) => {
    const kind = item.location.kind;
    if (kind) {
      count[kind] = (count[kind] || 0) + 1;
    }
    return count;
  }, {});
};

// Type guards
export const isUnit = (
  location?: Location.LocationApi.Location,
): location is Location.LocationApi.UnitWithTypename => {
  return location?.__typename === 'Unit';
};

export const isBuilding = (
  location?: Location.LocationApi.Location,
): location is Location.LocationApi.BuildingWithTypename => {
  return location?.__typename === 'Building';
};

export const isLocationGroup = (
  location?: Location.LocationApi.Location,
): location is Location.LocationApi.LocationGroupWithTypename => {
  return location?.__typename === 'LocationGroup';
};

export const hasLocationEdges = (
  location: Location.LocationApi.Location,
): location is
  | Location.LocationApi.BuildingWithTypename
  | Location.LocationApi.LocationGroupWithTypename => {
  return 'locations' in location;
};

export const gridServiceLevelComparator = (
  a: Schema.Unit.Item['serviceLevels'],
  b: Schema.Unit.Item['serviceLevels'],
) => {
  const serviceLevelA = a?.length || 0;
  const serviceLevelB = b?.length || 0;

  if (serviceLevelA === serviceLevelB) {
    return 0;
  }

  return serviceLevelA - serviceLevelB;
};

export const gridResidentsCountComparator = (
  a: Schema.Unit.Item['users'],
  b: Schema.Unit.Item['users'],
): number => {
  const userCountA = a?.edges?.length || 0;
  const userCountB = b?.edges?.length || 0;

  if (userCountA === userCountB) {
    return 0;
  }

  return userCountA - userCountB;
};

export const getLocationTypeLabelById = (id: string): string => {
  const item = locationTypes.find((type) => type.id === id);
  return item!.label;
};
