import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  conversationDetail,
  useConversationDetail as useConversationDetailReduxStore,
} from '@serenityapp/redux-store';

export default function useConversationDetail(conversationId: string) {
  const dispatch = useDispatch();

  const detail = useConversationDetailReduxStore(conversationId);

  useEffect(() => {
    dispatch(conversationDetail({ conversationId }));
  }, [dispatch, conversationId]);

  return detail;
}
