import { Schema } from '@serenityapp/domain';
import { Cache, DataFields, ResolveInfo } from '@urql/exchange-graphcache';
import { addLocation } from './utils';

export default function buildingCreate(
  result: DataFields,
  _args: Schema.Building.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdBuildingOutput = result?.result as Schema.Building.Create.Output;

  if (createdBuildingOutput.success) {
    const { organizationId, displayName, id, name } = createdBuildingOutput.item;

    const location: Schema.Location.ItemWithTypename = {
      id,
      displayName: displayName ?? 'Building',
      kind: 'Building',
      name,
      __typename: 'Building',
    };

    addLocation(location, organizationId, cache);
  }
}
