import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as R from 'ramda';

import { IdFn } from '@serenityapp/core';
import {
  ChannelCreateEditFormValues,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import { ConversationKind, ConversationSubjectKind, RouteParamId } from '@serenityapp/domain';
import {
  conversationAdd,
  getIsChannelAdding,
  getIsConversationDetailLoading,
  getIsUserDetailLoading,
  RootState,
} from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

import ChannelCreateEditForm from './components/ChannelCreateEditForm';

const ChannelCreateDrawer = () => {
  const makeTestId = useMakeTestId('ChannelCreateDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams<RouteParamId>() as RouteParamId;

  const isChannelAdding = useSelector(getIsChannelAdding);

  // this allows us to fix a possible race condition - see PR #2363
  const isUserDetailLoading = useSelector((state: RootState) =>
    getIsUserDetailLoading(state, id),
  );
  const isConversationDetailLoading = useSelector((state: RootState) =>
    getIsConversationDetailLoading(state, id),
  );
  const isDetailLoading = isUserDetailLoading || isConversationDetailLoading;

  const navigateBack = () => navigate('..');

  const handleFormSubmit = async (values: ChannelCreateEditFormValues) => {
    const channelId = IdFn.new();
    const mapToUserIds = R.map<SelectorItemProps, { userId: string }>((item) => ({
      userId: item.id,
    }));

    const conversationToAdd = {
      id: channelId,
      isDraft: false,
      kind: ConversationKind.CHANNEL,
      subject: ConversationSubjectKind.GENERAL,
      name: values.name?.trim(),
      description: values.description?.trim(),
      familyAndFriends: mapToUserIds(values.familyAndFriends as Array<SelectorItemProps>),
      staff: mapToUserIds(values.staff as Array<SelectorItemProps>),
      locations: R.pluck('id', values.locations || []),
      isOneWayChannel: values.isOneWayChannel || false,
    };

    dispatch(
      conversationAdd({
        conversation: conversationToAdd,
        onFailed: navigateBack,
        onSuccess: navigateBack,
      }),
    );
  };

  return (
    <ChannelCreateEditForm
      isOwnerAdmin
      dataTestId={makeTestId('')}
      handleFormClose={navigateBack}
      handleFormSubmit={handleFormSubmit}
      isLoading={isDetailLoading}
      isSaving={isChannelAdding}
      title="Create new general channel"
    />
  );
};

export default ChannelCreateDrawer;
