import { GetUploadURLQueryFn } from '@serenityapp/api-graphql';
import { DateFn, IdFn, MimeFn, PathFn } from '@serenityapp/core';
import { IApiClient } from '@serenityapp/core-graphql';
import {
  AbstractStorageProvider,
  ensureExtension,
  IStorageProvider,
  IStorageProviderConfig,
  StorageGetFileInput,
  StorageGetFileOutput,
  StorageGetNetworkUrlInput,
  StoragePrepareDescriptorInput,
  StoragePrepareDescriptorOutput,
  StoragePutFileInput,
  StoragePutFileOutput,
} from '@serenityapp/core-storage';

export interface IStorageProviderConfigS3 extends IStorageProviderConfig {
  bucket: string;
  region: string;
}

class StorageProviderWeb extends AbstractStorageProvider implements IStorageProvider {
  constructor(
    readonly config: IStorageProviderConfigS3,
    readonly apiClient: IApiClient,
  ) {
    super(config);
  }

  async getFile(input: StorageGetFileInput): Promise<StorageGetFileOutput> {
    return {
      success: input.url === undefined ? false : true,
      path: input.url,
    };
  }

  async getNetworkUrl(input: StorageGetNetworkUrlInput): Promise<string> {
    if (input.url === undefined) {
      throw new Error(`Could not get network URL for file ${input.key}`);
    }

    return input.url;
  }

  /**
   * Given Object URL, returns corresponding File instance
   */
  private async getBlobByObjectURL(objectURL: string): Promise<Blob | undefined> {
    const response = await fetch(objectURL);
    const blob = await response.blob();

    if (blob !== undefined && blob instanceof Blob) {
      return blob;
    }

    return undefined;
  }

  /**
   * Generates pre-signed URL for file upload
   */
  private async getPresignedUploadURL({ descriptor }: StoragePutFileInput): Promise<string> {
    const response = await GetUploadURLQueryFn.query(this.apiClient, {
      input: {
        contentType: descriptor.contentType,
        identityId: descriptor.identityId,
        key: descriptor.key,
        level: descriptor.level,
      },
    });
    return response.data.uploadUrl;
  }

  async prepareDescriptor(
    input: StoragePrepareDescriptorInput,
  ): Promise<StoragePrepareDescriptorOutput> {
    this.config.analytics.track('storage-provider-s3/prepare-descriptor-start');
    const start = DateFn.now();

    // Get basic file info (MIME type, file name, ...)
    const contentName = input.originalFile.name || PathFn.basename(input.originalFile.uri);
    const contentType =
      input.originalFile.type ||
      this.mimelookup.lookup(input.originalFile.name ?? input.originalFile.uri) ||
      this.defaultMimeType;
    const key = ensureExtension(this.mimelookup, IdFn.new(), contentType);

    // Get current user's unique identity ID (helps find their storage folder)
    const identityId = await this.config.identifyIdFn();
    if (!identityId) {
      throw new Error('Unable to resolve identity ID');
    }

    // Get file's dimensions
    let height: number | undefined;
    let width: number | undefined;
    if (MimeFn.isImage(contentType)) {
      const imageInfo = await this.imageInfoLookup.getInfo(input.originalFile.uri);
      height = imageInfo.height;
      width = imageInfo.width;
    }

    const elapsed = DateFn.now() - start;
    this.config.analytics.track({
      name: 'storage-provider-s3/prepare-descriptor-end',
      attributes: { amplifyElapsed: elapsed },
    });

    return {
      descriptor: {
        bucket: this.config.bucket,
        contentName,
        contentType,
        height,
        identityId,
        key,
        level: 'protected',
        region: this.config.region,
        schema: '1.0.0',
        // "verified" marks message attachments that are verified to be correctly uploaded.
        // Since we only send a message after its attachments have been uploaded now, we can set it manually to true.
        verified: true,
        width,
      },
      elapsed: {
        amplify: elapsed,
      },
      key,
      originalFile: input.originalFile,
      success: true,
    };
  }

  async putFile(input: StoragePutFileInput): Promise<StoragePutFileOutput> {
    this.sentry.addBreadcrumb({
      message: 'StorageProviderWeb putFile called',
      data: { input },
    });
    this.config.analytics.track('storage-provider-s3/put-file-start');
    const start = DateFn.now();

    const uploadUrl = await this.getPresignedUploadURL(input);

    const blob = await this.getBlobByObjectURL(input.originalFile.uri);

    if (blob === undefined) {
      throw new Error('Could not get file by its object URI');
    }

    const file = new File([blob], input.originalFile.name ?? input.descriptor.contentName, {
      type: blob.type,
    });

    const { status } = await fetch(uploadUrl, {
      method: 'PUT',
      body: file,
    });
    const isUploadSuccessful = status < 400;

    const elapsed = DateFn.now() - start;
    this.config.analytics.track({
      name: 'storage-provider-s3/put-file-end',
      attributes: { amplifyElapsed: elapsed },
    });

    return {
      descriptor: input.descriptor,
      elapsed: {
        amplify: elapsed,
      },
      localPath: input.originalFile.uri,
      success: isUploadSuccessful,
    };
  }
}

export async function createStorageProvider(
  storageProviderConfigS3: IStorageProviderConfigS3,
  apiClient: IApiClient,
) {
  const provider = new StorageProviderWeb(storageProviderConfigS3, apiClient);
  await provider.init();

  return provider;
}
