import { Drawer, theme } from '@serenityapp/components-react-web';
import {
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
  FormikValues,
  isFunction,
} from 'formik';
import { delay } from 'lodash';
import { ReactNode, useState } from 'react';
import ConfirmCloseDialog from './ConfirmCloseDialog';

type FormikDrawerProps = {
  children: ((props: FormikProps<FormikValues>) => ReactNode) | ReactNode;
  initialValues: Partial<FormikValues>;
  onSubmit: (values: FormikValues, helpers: FormikHelpers<FormikValues>) => void;
  validateForm?: (
    values?: FormikValues,
  ) => Promise<FormikErrors<FormikValues>> | FormikErrors<FormikValues>;
  shouldConfirmClose: boolean;
  onClose: () => void;
  enableReinitialize?: boolean;
};

const FormikDrawer = ({
  children,
  initialValues,
  onSubmit,
  shouldConfirmClose,
  validateForm,
  onClose,
  enableReinitialize = true,
}: FormikDrawerProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const onCancelConfirmDialogClick = () => setIsConfirmDialogOpen(false);

  const closeDrawer = () => {
    setIsDrawerOpen(false);

    // Wait for the closing drawer animation to ends before navigating out of it (195ms)
    delay(onClose, theme.transitions.duration.leavingScreen);
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<FormikValues>) => {
        const onCloseDrawerClick = () => {
          if (shouldConfirmClose && formikProps.dirty) {
            setIsConfirmDialogOpen(true);
            return;
          }

          closeDrawer();
        };

        const onDiscardClick = () => {
          setIsConfirmDialogOpen(false);
          closeDrawer();
        };

        return (
          <Drawer open={isDrawerOpen} onClose={onCloseDrawerClick}>
            {isFunction(children) ? children(formikProps) : children}
            <ConfirmCloseDialog
              open={isConfirmDialogOpen}
              onCancelClick={onCancelConfirmDialogClick}
              onDiscardClick={onDiscardClick}
            />
          </Drawer>
        );
      }}
    </Formik>
  );
};

export default FormikDrawer;
