import * as Sentry from '@sentry/react';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';
import { cacheExchange } from '@urql/exchange-graphcache';
import { Auth } from 'aws-amplify';
import { Client, CombinedError, fetchExchange } from 'urql';
import { devtoolsExchange } from '@urql/devtools';
import urqlSchema from '@serenityapp/api-client-graph/generated/urqlSchema.json';
import {
  buildingCreate,
  locationGroupCreate,
  unitCreate,
  unitRemove,
  locationGroupRemove,
  buildingRemove,
  buildingLocationGroupConvert,
  locationGroupBuildingConvert,
} from './urql';
import onePageCacheResolver from './urql/resolvers/onePageCacheResolver';

const cache = cacheExchange({
  schema: urqlSchema,
  keys: {
    UserAccount: () => null,
    Correlation: () => null,
    Address: () => null,
  },
  resolvers: {
    Organization: {
      locations: onePageCacheResolver,
      devices: onePageCacheResolver,
    },
  },
  updates: {
    Mutation: {
      buildingCreate,
      buildingRemove,
      buildingLocationGroupConvert,
      locationGroupRemove,
      locationGroupCreate,
      locationGroupBuildingConvert,
      unitCreate,
      unitRemove,
    },
  },
});

export function createClient(graphqlEndpoint: string) {
  const client = new Client({
    url: graphqlEndpoint,
    exchanges: [
      // Hooks up the URQL devtools (browser extension)
      devtoolsExchange,
      // Handles client-side cache
      // @urql/exchange-graphcache is a better alternative as it's normalized, but
      // it cannot work with aliased fields out-of-the-box and I didn't have time to
      // fix it yet, so I'm using the default cacheExchange (denormalized) for now.
      cache,
      // Retries the request if it fails
      retryExchange({
        initialDelayMs: 1000,
        maxDelayMs: 6000,
        randomDelay: true,
        maxNumberAttempts: 3,
        retryIf: (err) => err.networkError !== undefined && !isAuthError(err),
      }),
      // Handles authentication
      authExchange(async (utils) => {
        let session = await Auth.currentSession().catch((error: any) => {
          if (error.message !== 'No current user') {
            Sentry.addBreadcrumb({
              message: 'Failed to get current session when creating URQL client',
              data: { error },
            });
          }
        });

        return {
          // Adds the token to the headers of every request
          addAuthToOperation(operation) {
            try {
              const token = session?.getIdToken().getJwtToken();

              if (!token) {
                return operation;
              }

              return utils.appendHeaders(operation, { Authorization: token });
            } catch (error) {
              return operation;
            }
          },
          // willAuthError is called before sending a request.
          // Checks if the token has expired.
          // If it has, it will return true and refreshAuth is triggered.
          willAuthError() {
            try {
              return !session || !session.isValid();
            } catch (error: any) {
              return true;
            }
          },
          // Checks if the error is an authentication error and if it is, refreshes the token
          didAuthError(error, _operation) {
            return isAuthError(error);
          },
          // Refreshes the token
          async refreshAuth() {
            try {
              session = await Auth.currentSession();
              if (!session.isValid()) {
                await Auth.signOut();
              }
            } catch (error) {
              await Auth.signOut();
            }
          },
        };
      }),
      // Default "exchange" that just handles sending the request and fetching the response
      fetchExchange,
    ],
  });

  return client;
}

function isAuthError(error: CombinedError): boolean {
  const is401 = error.response?.status === 401;
  const tokenExpired = error.graphQLErrors.some((e) => e.message.includes('Token has expired'));
  return is401 || tokenExpired;
}
