import { useEffect } from 'react';
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement } from '@lexical/utils';
import data from '@emoji-mart/data/sets/14/google.json';
import { EmojiData, EmojiObject } from '../types';
import EmojiNode, { $createEmojiNode } from '../nodes/EmojiNode';
import { INSERT_EMOJI_COMMAND } from '../customLexicalCommands';

const EmojiPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([EmojiNode])) {
      throw new Error('EmojiPlugin: EmojiNode not registered on editor');
    }

    return editor.registerCommand(
      INSERT_EMOJI_COMMAND,
      (emojiData: EmojiData) => {
        // Find emoji id within `emoji-mart` data using native glyph.
        const nativeEmojiID = (data as any).natives[emojiData.native];

        // If the emoji id is not found by native glyph, we return.
        if (!nativeEmojiID) return true;

        // Take emoji id and find its related data within emojis map from `emoji-mart` data
        const emoji = (data as any).emojis[nativeEmojiID];

        const skinToneId = (emojiData.skin || 1) - 1;
        const emojiSkinById = emoji.skins[skinToneId];

        const emojiObject: EmojiObject = {
          id: emoji.id,
          native: emojiSkinById.native,
          unified: emojiSkinById.unified,
          name: emoji.name,
          skin: skinToneId,
          keywords: emoji.keywords,
          emoticons: emoji.emoticons,
          x: emojiSkinById.x,
          y: emojiSkinById.y,
        };

        const emojiNode = $createEmojiNode(emojiObject, emojiObject.native);

        $insertNodes([emojiNode]);
        if ($isRootOrShadowRoot(emojiNode.getParentOrThrow())) {
          $wrapNodeInElement(emojiNode, $createParagraphNode).selectEnd();
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return null;
};

export default EmojiPlugin;
