import { useEffect, useMemo, useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Alert, Box, Button, IconButton } from '@mui/material';
import { Close, Add } from '@mui/icons-material';

import { useSerenityField } from '@serenityapp/components-react-common';
import { FormikField } from '@serenityapp/components-react-web';

type Floor = {
  floorName?: string;
  floorNumber: string;
  id?: string;
};

const FloorsFields = ({ name }: { name: string }) => {
  const [, field, meta] = useSerenityField({ name });

  const [isDuplicateDetected, setIsDuplicateDetected] = useState(false);

  const errors = useMemo(() => {
    return meta.error ? meta.error : [];
  }, [meta.error]);

  useEffect(() => {
    const hasDuplicate = Object.values(errors).includes('Duplicate');
    setIsDuplicateDetected(hasDuplicate);
  }, [errors]);

  const getNextFloorNumber = () => {
    const highestFloor = field.value.reduce((acc: number, floor: Floor) => {
      const currentFloorNumber = parseInt(floor.floorNumber, 10);
      return currentFloorNumber > acc ? currentFloorNumber : acc;
    }, 0);
    return highestFloor + 1;
  };

  const onRemoveFloor = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    arrayHelpers.remove(index);
  };

  return (
    <Box>
      {isDuplicateDetected && (
        <Alert severity="error" sx={alertSx} variant="outlined">
          The same floor number has been assigned to multiple floors. Please select a unique
          number for each floor.
        </Alert>
      )}
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <Box>
            {field.value.map((floor: Floor, index: number) => {
              const floorNumberError = errors[index];
              return (
                <Box key={`${name}.${index}`} sx={wrapperSx}>
                  <FormikField
                    error={!!floorNumberError}
                    helperText="Required"
                    label="Floor #"
                    name={`${name}.${index}.floorNumber`}
                    sx={floorNumberFieldSx}
                    type="number"
                  />
                  <FormikField
                    label="Floor name"
                    name={`${name}.${index}.name`}
                    sx={floorNameFieldSx}
                  />
                  <IconButton
                    sx={removeButtonSx}
                    onClick={() => onRemoveFloor(arrayHelpers, index)}
                  >
                    <Close />
                  </IconButton>
                </Box>
              );
            })}
            <Button
              fullWidth
              color="primary"
              startIcon={<Add />}
              sx={{ my: 2 }}
              type="button"
              variant="outlined"
              onClick={() => arrayHelpers.push({ floorNumber: getNextFloorNumber(), name: '' })}
            >
              Add floor
            </Button>
          </Box>
        )}
      />
    </Box>
  );
};

const wrapperSx = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  mb: 1,
  gap: 2,
};

const removeButtonSx = {
  display: 'flex',
  alignSelf: 'flex-start',
  mt: 1,
};

export const alertSx = {
  mb: 4,
  alignItems: 'center',
};

const floorNumberFieldSx = {
  width: 120,
  flexGrow: 0,
};

const floorNameFieldSx = {
  flexGrow: 1,
};

export default FloorsFields;
