import * as z from 'zod';

// Base schema for any floor
const floorSchema = z.object({
  floorName: z.string().optional(),
  floorNumber: z.number(),
});

const floorsSchema = z.array(floorSchema).superRefine((floors, context) => {
  const floorNumberIndices = new Map();

  // Collect all indices of each floor number to check for duplicates
  floors.forEach((floor, index) => {
    if (!floorNumberIndices.has(floor.floorNumber)) {
      floorNumberIndices.set(floor.floorNumber, []);
    }
    floorNumberIndices.get(floor.floorNumber).push(index);
  });

  // Add issues for duplicates found
  floorNumberIndices.forEach((indices, number) => {
    if (indices.length > 1) {
      indices.forEach((index: number) => {
        context.addIssue({
          message: 'Duplicate',
          code: z.ZodIssueCode.custom,
          path: [index, 'floorNumber'], // Target the specific floor number field that has duplicates
        });
      });
    }
  });
});

const objectSchema = z
  .object({ id: z.string().optional(), label: z.string().optional() })
  .nullable()
  .default({});

const selectorItemPropsSchema = z.object({
  id: z.string(),
  label: z.string(),
  subtitle: z.string().optional(),
  avatar: z
    .object({ src: z.string().optional(), initials: z.string(), alt: z.string() })
    .optional(),
});

const buildingValidationSchema = z.object({
  locationType: z.literal('Building'),
  displayName: z.string().optional(),
  floors: floorsSchema,
  locationGroup: objectSchema,
});

const unitValidationSchema = z.object({
  locationType: z.literal('Unit').default('Unit'),
  displayName: z.string().optional().default(''),
  pccRecordStamp: z.string().optional().nullable(),
  serviceLevel: z.string().optional(),
  users: z.array(selectorItemPropsSchema).optional(),
  floor: objectSchema,
  building: objectSchema,
  locationGroup: objectSchema,
  floorId: z.string().optional().nullable(),
  buildingId: z.string().optional().nullable(),
  locationGroupId: z.string().optional().nullable(),
});

const commonValidationSchema = z.object({
  id: z.string().optional(),
  locationName: z.string().min(1, { message: 'Required' }),
});

const unitValidationSchemaAllFields = commonValidationSchema.merge(unitValidationSchema);

export type UnitFormValues = z.infer<typeof unitValidationSchemaAllFields>;

const locationAddressSchema = z.object({
  countryOrRegion: z.string().optional(),
  addressLine1: z.string().optional(),
  addressLine2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.string().optional(),
});

const locationGroupSchema = z.object({
  locationType: z.literal('LocationGroup').default('LocationGroup'),
});

export const validationSchema = commonValidationSchema
  .merge(locationAddressSchema)
  .and(
    z.discriminatedUnion('locationType', [
      unitValidationSchema,
      buildingValidationSchema,
      locationGroupSchema,
    ]),
  );
