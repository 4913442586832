import { useState, useEffect } from 'react';
import { useQuery } from 'urql';
import { OrganizationResult, organizationLocationsQuery } from '@serenityapp/api-graphql';
import { Schema } from '@serenityapp/domain';
import type { PageInfo } from '@serenityapp/domain-graphql';
import { DEFAULT_LOCATIONS_PAGE_SIZE } from './utils';

type UseLocationsReturnType = {
  locations: Schema.Location.Item[];
  isFetching: boolean;
};

/**
 * This hook is used to continuously fetch locations pages until there is no more data to fetch.
 *
 * @param organizationId
 * @returns A list of locations and a boolean indicating if the data is still loading.
 */
const useLocations = (organizationId: string): UseLocationsReturnType => {
  const [lastPageInfo, setLastPageInfo] = useState<PageInfo>({
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false,
    startCursor: '',
  });
  const [isFetching, setFetching] = useState(true);

  const [result] = useQuery<OrganizationResult>({
    query: organizationLocationsQuery,
    variables: {
      getInput: { id: organizationId },
      pageInput: { first: DEFAULT_LOCATIONS_PAGE_SIZE, after: lastPageInfo.endCursor },
      input: { kinds: ['LocationGroup', 'Building', 'Unit'] },
    },
    requestPolicy: 'cache-and-network',
  });

  const { edges, pageInfo } = result?.data?.organization?.locations || {};

  useEffect(() => {
    if (pageInfo) {
      if (pageInfo.endCursor !== lastPageInfo.endCursor) {
        setLastPageInfo(pageInfo);
      }
      if (!pageInfo.hasNextPage) {
        setFetching(false);
      }
    }
  }, [lastPageInfo.endCursor, pageInfo]);

  const locations: Schema.Location.Item[] =
    edges?.map((edge: Schema.Organization.Locations.Edge) => edge.location) || [];

  return { locations, isFetching };
};

export default useLocations;
