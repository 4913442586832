import { UseQueryArgs, useQuery } from 'urql';
import { Schema } from '@serenityapp/domain';
import { Location } from '@serenityapp/api-graphql';

type UseLocationDetailReturnType = {
  location?: Location.LocationApi.Location;
  isLocationStale: boolean;
  isLocationDetailFetching: boolean;
};

/**
 * This hook is used to fetch the location detail by its ID.
 *
 * @param id
 */
const useLocationDetail = (id: string): UseLocationDetailReturnType => {
  const locationGetQueryOptions: UseQueryArgs<
    Schema.Location.Get.Variables,
    Location.LocationApi.LocationGetResult
  > = {
    query: Location.LocationApi.locationGetQuery,
    requestPolicy: 'cache-and-network',
    variables: {
      input: {
        id,
      },
    },
  };

  const [queryResult] = useQuery(locationGetQueryOptions);
  const { data, fetching: isLocationDetailFetching, stale: isLocationStale } = queryResult;
  const location = data && data.result;

  return {
    location,
    isLocationStale,
    isLocationDetailFetching,
  };
};

export default useLocationDetail;
