import { useMemo } from 'react';
import { UseQueryArgs, useQuery } from 'urql';

import { Schema } from '@serenityapp/domain';
import { organizationCampusesQuery, OrganizationCampusesResult } from '@serenityapp/api-graphql';

import { useCurrentUser } from '../../../common/hooks';

type UseCampusReturnType = {
  campus?: Schema.Campus.Item;
  isCampusFetching: boolean;
};

/**
 * This hook is used to fetch the campus of the current organization,
 * it considers that there is only one campus.
 */
const useCampus = (): UseCampusReturnType => {
  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId || '';

  const campusesQueryOptions: UseQueryArgs<
    Schema.Organization.Campuses.Variables,
    OrganizationCampusesResult
  > = useMemo(
    () => ({
      query: organizationCampusesQuery,
      requestPolicy: 'cache-and-network',
      variables: {
        getInput: {
          id: organizationId,
        },
      },
    }),
    [organizationId],
  );

  const [campusesResult] = useQuery(campusesQueryOptions);
  const { data, fetching } = campusesResult;

  const campus = data?.result?.campuses.edges[0].campus;

  return { campus, isCampusFetching: fetching };
};

export default useCampus;
