import { SyntheticEvent, useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

import { FormikField } from '@serenityapp/components-react-web';
import { SelectorItemProps } from '@serenityapp/components-react-common';

import { useLocationGroups } from '../hooks';
import { dividerSx } from './styles';

import AddressFields from './AddressFields';
import FloorsField from './FloorsFields';
import AutocompleteField from './AutocompleteField';

type BuildingFieldsProps = {
  setIsDataLoading: (value: boolean) => void;
};

const BuildingFields = ({ setIsDataLoading }: BuildingFieldsProps) => {
  const { setFieldValue, initialValues } = useFormikContext();
  const { locationGroupId } = initialValues as FormikValues;

  const [selectedLocationGroupId, setSelectedLocationGroupId] = useState(locationGroupId);

  const { locationGroupOptions, locationGroupsFetching } = useLocationGroups();

  useEffect(() => {
    setIsDataLoading(locationGroupsFetching);
  }, [locationGroupsFetching, setIsDataLoading]);

  useEffect(() => {
    setSelectedLocationGroupId(locationGroupId);
  }, [initialValues, locationGroupId]);

  const selectedLocationGroupItem: SelectorItemProps | undefined = locationGroupOptions.find(
    (item: SelectorItemProps) => item.id === selectedLocationGroupId,
  );

  const handleLocationGroupChange = (event: SyntheticEvent, value: SelectorItemProps) => {
    setFieldValue('locationGroup', value);
    setFieldValue('locationGroupId', value?.id);
    setSelectedLocationGroupId(value?.id || null);
  };

  return (
    <>
      <FormikField
        helperText="Overwrite default location type name"
        label="Display name"
        name="displayName"
        type="text"
      />
      <AutocompleteField
        items={locationGroupOptions}
        label="Location Group"
        name="locationGroup"
        value={selectedLocationGroupItem || null}
        onChange={handleLocationGroupChange}
      />
      <Typography display="block" variant="overline">
        floors
      </Typography>
      <Divider sx={dividerSx} />
      <FloorsField name="floors" />
      <AddressFields />
    </>
  );
};

export default BuildingFields;
