import { useDispatch } from 'react-redux';
import { useMemo, useState } from 'react';
import { FormikValues } from 'formik';
import { useMutation } from 'urql';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteParamId, Schema } from '@serenityapp/domain';
import { Building, LocationGroup, Unit } from '@serenityapp/api-graphql';
import { AddressFn, IdFn } from '@serenityapp/core';
import { snackAdd } from '@serenityapp/redux-store';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevelFn } from '@serenityapp/core';

import { LocationForm } from './components';
import { useBuildings, useCampus, useLocationDetail, useLocationGroups } from './hooks';
import {
  MULTI_PURPOSE_SERVICE_LEVEL,
  getServiceLevelsFromUsers,
  isBuilding,
  isUnit,
} from './utils';

const LocationEditDrawer = () => {
  const { id } = useParams<RouteParamId>() as RouteParamId;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [, updateBuilding] = useMutation<
    Schema.Building.Update.Output,
    Schema.Building.Update.Variables
  >(Building.BuildingApi.buildingUpdateMutation);

  const [, updateUnit] = useMutation<Schema.Unit.Update.Output, Schema.Unit.Update.Variables>(
    Unit.Api.unitUpdateMutation,
  );

  const [, updateLocationGroup] = useMutation<
    Schema.LocationGroup.Update.Output,
    Schema.LocationGroup.Update.Variables
  >(LocationGroup.Api.locationGroupUpdate);

  const { locationGroups } = useLocationGroups();
  const { buildings } = useBuildings();
  const { campus, isCampusFetching } = useCampus();
  const { location, isLocationStale, isLocationDetailFetching } = useLocationDetail(id);

  const handleSuccess = (locationType: string) => {
    setIsLoading(false);
    navigate('../');
    dispatch(snackAdd({ message: `${locationType} was successfully updated`, type: 'success' }));
  };

  const handleError = (locationType: string) => {
    setIsLoading(false);
    dispatch(snackAdd({ message: `Error updating ${locationType}`, type: 'error' }));
  };

  const handleBuildingUpdate = (values: FormikValues) => {
    const locationType = values.locationType;
    const isLocationGroup = !!values?.locationGroupId;

    const floors = values.floors.map((floor: any) => ({
      floorName: floor?.name || undefined,
      id: floor.id ?? IdFn.xnew('loc_'),
      floorNumber: floor.floorNumber,
    }));

    /**
     * Rob: Address is "all or nothing."
     * It can be undefined, but if it is defined, all fields must be present.
     */
    const address = AddressFn.optional(values);

    updateBuilding({
      input: {
        id: values.id,
        name: values.locationName,
        displayName: values.displayName || 'Building',
        parentKind: isLocationGroup ? 'LocationGroup' : 'Campus',
        parentId: isLocationGroup ? values.locationGroupId : campus?.id,
        floors,
        address,
      },
    }).then((result) => (result.error ? handleError(locationType) : handleSuccess(locationType)));
  };

  const handleLocationGroupUpdate = (values: FormikValues) => {
    const locationType = values.locationType;
    updateLocationGroup({
      input: {
        id: values.id,
        name: values.locationName,
        displayName: 'Location Group',
      },
    }).then((result) => (result.error ? handleError(locationType) : handleSuccess(locationType)));
  };

  const handleUnitUpdate = (values: FormikValues) => {
    const locationType = values.locationType;
    let parentKind: Schema.Location.Kind;
    let parentId: string;

    /**
     * Rob: Address is "all or nothing."
     * It can be undefined, but if it is defined, all fields must be present.
     */
    const address = AddressFn.optional(values);

    // Determine parentKind and parentId based on the presence of floorId and buildingId
    if (values?.floorId) {
      parentKind = 'Floor';
      parentId = values.floorId;
    } else if (values?.buildingId) {
      parentKind = 'Building';
      parentId = values.buildingId;
    } else if (values?.locationGroupId) {
      parentKind = 'LocationGroup';
      parentId = values.locationGroupId;
    } else {
      // If none is defined
      parentKind = 'Campus';
      parentId = campus?.id || '';
    }
    updateUnit({
      input: {
        id: values.id,
        name: values.locationName,
        displayName: values.displayName || 'Unit',
        parentId,
        parentKind,
        serviceLevels: values.serviceLevel
          ? values.serviceLevel === MULTI_PURPOSE_SERVICE_LEVEL
            ? getServiceLevelsFromUsers(values.users)
            : [values.serviceLevel]
          : ['UNKNOWN'],
        userIds: values.users?.map((user: SelectorItemProps) => user.id),
        address,
      },
    }).then((result) => (result.error ? handleError(locationType) : handleSuccess(locationType)));
  };

  const handleFormSubmit = async (values: FormikValues) => {
    setIsLoading(true);

    const locationType = values.locationType;

    switch (locationType) {
      case 'Building':
        handleBuildingUpdate(values);
        break;

      case 'Unit': {
        handleUnitUpdate(values);
        break;
      }
      case 'LocationGroup':
        handleLocationGroupUpdate(values);
        break;

      default:
        break;
    }
  };

  const serviceLevel: string | undefined = useMemo(() => {
    if (isUnit(location)) {
      const serviceLevels = location.serviceLevels ?? [];
      return serviceLevels.length > 1 ? 'MULTI_PURPOSE' : serviceLevels[0];
    }
    return undefined;
  }, [location]);

  const buildingFloors = isBuilding(location)
    ? (location as Schema.Building.Item).locations?.edges.filter(
        (edge: Schema.Building.Locations.Edge) => edge.location.kind === 'Floor',
      ) || []
    : [];

  const floors = buildingFloors
    ?.map((edge: Schema.Building.Locations.Edge) => {
      const floor = edge.location as unknown as Schema.Floor.Item;
      return {
        floorNumber: floor.floorNumber,
        name: floor.floorName || '',
        id: floor.id,
      };
    })
    .sort((a: Schema.Floor.Item, b: Schema.Floor.Item) => a.floorNumber - b.floorNumber);

  const users = useMemo(() => {
    if (isUnit(location) && location.users) {
      return location.users.edges.map((edge: Schema.Unit.Users.Edge) => ({
        id: edge.user.id,
        label: edge.user.fullName,
        subtitle: ServiceLevelFn.abbreviation(edge.user.serviceLevel),
        avatar: {
          src: edge.user.avatar?.url,
          alt: edge.user.fullName,
          initials: edge.user.initials,
        },
      }));
    }
    return [];
  }, [location]);

  const getLocationGroupId = (
    parentId?: string,
    locationGroups?: Schema.Organization.LocationGroups.Connection,
  ) => {
    if (!locationGroups || !parentId) return undefined;
    const foundLocationGroup = locationGroups?.edges.find((edge) => {
      return edge.locationGroup.locations?.edges?.some(
        (locationEdge: Schema.LocationGroup.Locations.Edge) =>
          locationEdge.location.id === parentId,
      );
    });
    return foundLocationGroup?.locationGroup.id;
  };

  const getBuildingId = (
    parentId?: string,
    buildings?: Schema.Organization.Buildings.Connection,
  ) => {
    if (!buildings || !parentId) return undefined;
    const foundBuilding = buildings?.edges?.find((edge) => {
      return edge.building.locations?.edges?.some(
        (locationEdge: Schema.Building.Locations.Edge) => locationEdge.location.id === parentId,
      );
    });
    return foundBuilding?.building.id;
  };

  const initialValues = useMemo(() => {
    if (location) {
      const locationType = location.kind;
      let floorId;
      let buildingId;
      let locationGroupId;

      if (locationType === 'Unit') {
        switch (location.parentKind) {
          case 'LocationGroup':
            locationGroupId = location.parentId;
            break;
          case 'Building':
            buildingId = location.parentId;
            locationGroupId = getLocationGroupId(location?.parentId, locationGroups);
            break;
          case 'Floor':
            floorId = location.parentId;
            buildingId = getBuildingId(location?.parentId, buildings);
            if (buildingId) {
              locationGroupId = getLocationGroupId(buildingId, locationGroups);
            }
            break;
          default:
            break;
        }
      }
      if (locationType === 'Building' && location.parentKind === 'LocationGroup') {
        locationGroupId = location.parentId;
      }

      const pccRecordStamp = isUnit(location) && location.pccRecordStamp;
      return {
        id: location?.id,
        locationName: location?.name || '',
        locationType: location?.kind,
        displayName: location?.displayName || '',
        // Building
        floors,
        // Address
        city: location?.address?.city || '',
        postalCode: location?.address?.postalCode || '',
        stateOrRegion: location?.address?.stateOrRegion || '',
        countryOrRegion: location?.address?.countryOrRegion || '',
        addressLine1: location?.address?.addressLine1 || '',
        addressLine2: location?.address?.addressLine2 || '',
        // Unit
        serviceLevel,
        pccRecordStamp,
        users,
        floor: null,
        floorId,
        building: null,
        buildingId,
        locationGroup: null,
        locationGroupId,
      };
    } else {
      return {
        locationName: '',
        locationType: '',
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.id, floors]);

  return (
    <LocationForm
      editMode
      initialValues={initialValues}
      isFetching={isLocationDetailFetching || isLocationStale || isCampusFetching}
      isLoading={isLoading}
      location={location}
      onFormSubmit={handleFormSubmit}
    />
  );
};

export default LocationEditDrawer;
