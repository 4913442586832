import { $createLineBreakNode, $createTextNode } from 'lexical';
import { $createLinkNode, $isLinkNode, LinkNode } from '@lexical/link';
import { TextMatchTransformer } from '@lexical/markdown';
import { default as emojiRegularExpression } from 'emoji-regex';
import data from '@emoji-mart/data/sets/14/google.json';

import { URL_REGEX } from '@serenityapp/components-react-common';

import { default as EmojiNode, $createEmojiNode, $isEmojiNode } from '../nodes/EmojiNode';
import { EmojiObject } from '../types';

export const DEFAULT_EMOJI_SET = 'google';
export const LARGE_EMOJI_SIZE = 32;
export const REGULAR_EMOJI_SIZE = 22;

export const SKIN_TONE_DEFAULT = 0;
export const SKIN_TONE_1 = 1;
export const SKIN_TONE_2 = 2;
export const SKIN_TONE_3 = 3;
export const SKIN_TONE_4 = 4;
export const SKIN_TONE_5 = 5;

// Function to match a skin tone from emoji unicode
export const getSkinToneId = (emojiGlyph: string) => {
  const regex_skin_tone_1 = /[\u{1F3FB}]+/u;
  const regex_skin_tone_2 = /[\u{1F3FC}]+/u;
  const regex_skin_tone_3 = /[\u{1F3FD}]+/u;
  const regex_skin_tone_4 = /[\u{1F3FE}]+/u;
  const regex_skin_tone_5 = /[\u{1F3FF}]+/u;

  if (emojiGlyph.match(regex_skin_tone_1)) {
    return SKIN_TONE_1;
  } else if (emojiGlyph.match(regex_skin_tone_2)) {
    return SKIN_TONE_2;
  } else if (emojiGlyph.match(regex_skin_tone_3)) {
    return SKIN_TONE_3;
  } else if (emojiGlyph.match(regex_skin_tone_4)) {
    return SKIN_TONE_4;
  } else if (emojiGlyph.match(regex_skin_tone_5)) {
    return SKIN_TONE_5;
  } else {
    return SKIN_TONE_DEFAULT;
  }
};

export const getLinkTransformer = (): TextMatchTransformer => ({
  dependencies: [LinkNode],
  export: (node, exportChildren, exportFormat) => {
    if (!$isLinkNode(node)) {
      return null;
    }
    const linkContent = node.getTextContent();

    return linkContent;
  },
  importRegExp: URL_REGEX,
  regExp: URL_REGEX,
  replace: (textNode, match) => {
    const [linkText] = match;
    const httpsLinkText = linkText.startsWith('http') ? linkText : `https://${linkText}`;
    const linkNode = $createLinkNode(httpsLinkText, {
      title: linkText,
      target: '_blank',
      rel: 'noreferrer noopener',
    });
    const linkTextNode = $createTextNode(linkText);
    linkTextNode.setFormat(textNode.getFormat());
    linkNode.append(linkTextNode);
    textNode.replace(linkNode);
  },
  trigger: ')',
  type: 'text-match',
});

// LineBreakTransformer is more like a workaround for default markdown behavior
// when empty lines get removed in lexical $convertFromMarkdownString
export const getLineBreakTransformer = (): TextMatchTransformer => ({
  dependencies: [],
  export: () => {
    return null;
  },
  regExp: /↵/,
  importRegExp: /↵/,
  replace: (textNode) => {
    textNode.replace($createLineBreakNode());
  },
  trigger: '',
  type: 'text-match',
});

const EMOJI_REGEX = emojiRegularExpression();
// emoji-regex library contains regex for emoji unicodes including g flag. In emoji transformer
// we do not want g flag since we want to use regex for single and first match only. For that
// reason we have to remove the g flag from the regex.
const emojiRegexWithoutFlags = new RegExp(EMOJI_REGEX.source, '');

export const getEmojiTransformer = (shouldBeLarge?: boolean): TextMatchTransformer => {
  return {
    dependencies: [EmojiNode],
    export: (node) => {
      if (!$isEmojiNode(node)) {
        return null;
      }
      const emojiNative = node.__emojiObject.native;

      return emojiNative;
    },
    importRegExp: emojiRegexWithoutFlags,
    regExp: emojiRegexWithoutFlags,
    replace: (textNode, match) => {
      const emojiNative = match[0];

      // Find emoji id within `emoji-mart` data using matched unicode.
      const nativeEmojiID = (data as any).natives[emojiNative];

      // If the emoji id is not found by matched unicode, we return null.
      if (!nativeEmojiID) return null;

      // Take emoji id and find its related data within emojis map from `emoji-mart` data
      const emoji = (data as any).emojis[nativeEmojiID];

      // If an emoji is not found within `emoji-mart` data, we return null.
      if (!emoji) return null;

      const skinToneId = getSkinToneId(emojiNative);
      const emojiSkinById = emoji.skins[skinToneId];

      const emojiObject: EmojiObject = {
        id: emoji.id,
        native: emojiSkinById.native,
        unified: emojiSkinById.unified,
        name: emoji.name,
        skin: skinToneId,
        keywords: emoji.keywords,
        emoticons: emoji.emoticons,
        x: emojiSkinById.x || 0,
        y: emojiSkinById.y || 0,
      };

      if (!emojiObject) return;

      const emojiNode = $createEmojiNode(
        emojiObject,
        emojiObject.native,
        shouldBeLarge ? LARGE_EMOJI_SIZE : REGULAR_EMOJI_SIZE,
      );
      textNode.replace(emojiNode);
    },
    trigger: ':',
    type: 'text-match',
  };
};
