import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetail, RootState, userDetail } from '@serenityapp/redux-store';

export default function useUserDetail(userId: string) {
  const dispatch = useDispatch();

  const userDetails = useSelector((state: RootState) => getUserDetail(state, userId));

  useEffect(() => {
    dispatch(userDetail({ userId }));
  }, [dispatch, userId]);

  return {
    ...userDetails,
  };
}
