import { styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

const AdminGridData = styled(DataGridPro)(({ theme }) => ({
  margin: theme.spacing(2.5),
  borderColor: theme.palette.divider,

  '&.MuiDataGrid-root': {
    border: 0,
    marginTop: 0,

    '& .MuiDataGrid-virtualScroller, & .MuiDataGrid-columnHeaderTitleContainer': {
      background: theme.palette.background.paper,
    },
    '& .MuiDataGrid-columnHeaders, & .MuiDataGrid-footerContainer, & .MuiDataGrid-container--top [role=row]':
      {
        background: theme.palette.background.paper,
      },
    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within':
      {
        outline: 'none',
      },

    '& .MuiDataGrid-toolbarContainer': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
      paddingTop: 8,
      paddingBottom: 8,
      gap: 16,
      marginBottom: 24,

      '& .MuiDataGrid-toolbarQuickFilter': {
        padding: 0,
      },
    },

    '& .MuiDataGrid-main': {
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      borderWidth: '1px 1px 0 1px',
      borderRadius: '4px 4px 0 0',
    },

    '& .MuiDataGrid-footerContainer': {
      borderColor: theme.palette.divider,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: '0 0 4px 4px',
    },
  },
}));

export default AdminGridData;
