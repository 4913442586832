import { IdFn } from '@serenityapp/core';
// FIXME: (Michael) client-web should not depend on client-native
import { version } from '@serenityapp/client-native/package.json';

export function versionAttributes() {
  return {
    idKey: IdFn.fromString(version),
    webVersion: version,
  };
}
