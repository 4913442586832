import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';

import {
  AdministrationHeader,
  BasicTable,
  BasicTableHeadCellProps,
  useMakeTestId,
  View,
  SearchBar,
} from '@serenityapp/components-react-web';
import {
  getLocationsLoadingState,
  getAdministerableFilteredSortedLocations,
  RootState,
} from '@serenityapp/redux-store';
import { LocationProps } from '@serenityapp/domain';
import { useAdminBreadcrumbs } from '../hooks';

const FiltersPage = () => {
  const title = 'Filter categories';
  const makeTestId = useMakeTestId('FiltersPage');
  const mainTestId = makeTestId('');

  const [searchKey, setSearchKey] = useState('');

  const navigate = useNavigate();

  const isLoading: boolean = useSelector(getLocationsLoadingState);
  const locations: Array<LocationProps> = useSelector((state: RootState) =>
    getAdministerableFilteredSortedLocations(state, searchKey),
  );

  const shouldShowSkeleton = isLoading && locations.length === 0;

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'name', label: 'Name' },
    { id: 'subtype', label: 'Type', sort: false },
    { id: 'description', label: 'Description', sort: false, hide: 'smDown' },
  ];

  const goToResourceDetails = (id: string) => navigate(id);
  const goToResourceCreate = () => navigate('create');

  const crumbs = useAdminBreadcrumbs(title);

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;filter
      </Box>
    </Button>
  );

  return (
    <View>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <Box>
          <SearchBar
            dataTestId={makeTestId('Filter')}
            sx={searchBarSx}
            value={searchKey}
            onSearchKeyChange={setSearchKey}
          />
        </Box>
        <BasicTable
          aria-label="Filters table"
          dataTestId={mainTestId}
          handleRowClick={goToResourceDetails}
          headCells={headCells}
          rows={locations}
          showSkeleton={shouldShowSkeleton}
        />
        <Outlet />
      </View.Content>
    </View>
  );
};

const searchBarSx = {
  px: 2,
  pb: 1,
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default FiltersPage;
