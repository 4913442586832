import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import {
  snackAdd,
  conversationArchive,
  getIsChannelArchiving,
  RootState,
} from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

type ArchiveChannelDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  conversationName: string;
  conversationId: string;
  onActionSuccess?: () => void;
};

const ArchiveChannelDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  conversationName,
  conversationId,
  onActionSuccess,
}: ArchiveChannelDialogProps) => {
  const makeTestId = useMakeTestId('ArchiveChannelDialog', dataTestId);
  const archiveChannelDialog = makeTestId('');

  const isArchiving = useSelector((state: RootState) =>
    getIsChannelArchiving(state, conversationId),
  );
  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    dispatch(
      conversationArchive({
        conversationId,
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error archiving conversation', type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'Conversation successfully archived', type: 'success' }));
          onClose();
          onActionSuccess?.();
        },
      }),
    );
  };

  return (
    <Dialog
      fullWidth
      data-testid={archiveChannelDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Archive channel</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to Archive {conversationName}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>
            Archiving will remove {conversationName} from the conversations list.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isArchiving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isArchiving}
          variant="contained"
          onClick={handleConfirmClick}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ArchiveChannelDialog;
