import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CancelRounded, Add, CheckCircle, MoreVert } from '@mui/icons-material';
import {
  Typography,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import {
  useCheckInPolicies,
  useApps,
  appTransformer,
  snackAdd,
  appDisconnect,
  checkInPoliciesTransformer,
  useViewerBaseInformation,
  checkInPolicyArchive,
} from '@serenityapp/redux-store';
import { CHECK_IN_APP_NAME } from '@serenityapp/core';
import { CheckInPolicy, WithKey } from '@serenityapp/domain';
import {
  BasicMenu,
  BasicMenuItemProps,
  Drawer,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { ArchiveDialog } from '../../components';
import CheckInAppDrawerSkeleton from './CheckInAppDrawerSkeleton';

type CheckInAppDetailDrawerProps = {
  onAddClick: () => void;
  onEditActionClick: (id: string) => void;
};

const CheckInAppDetailDrawer = ({
  onAddClick,
  onEditActionClick,
}: CheckInAppDetailDrawerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const makeTestId = useMakeTestId('CheckInAppDetailDrawer');

  const [isArchiving, setIsArchiving] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);

  const [actionsMenuAnchorEl, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentlySelectedPolicy, setCurrentlySelectedPolicy] = useState<CheckInPolicy | null>(
    null,
  );

  const shouldDisplayActionsMenu = !!currentlySelectedPolicy;
  const openMenuAnchor = Boolean(actionsMenuAnchorEl);

  const { data: viewerData } = useViewerBaseInformation();
  const organizationId = viewerData.me.user?.orgId;

  const {
    data: checkInPoliciesData,
    isLoading: isLoadingCheckInPolicies,
    isFetching: isFetchingCheckInPolicies,
  } = useCheckInPolicies({
    variables: {},
  });

  const checkInPolicies = checkInPoliciesTransformer(
    checkInPoliciesData,
    isLoadingCheckInPolicies,
  );
  const noCheckInPolicies = checkInPolicies.length === 0;

  const { data: appsData, isAppDisconnecting } = useApps();
  const app = appTransformer(appsData, CHECK_IN_APP_NAME);
  const isCurrentAppDisconnecting = app?.node.id ? isAppDisconnecting[app.node.id] : false;

  const handleActionsMenuClick = (
    policy: CheckInPolicy,
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setActionsMenuAnchor(event.currentTarget);
    setCurrentlySelectedPolicy(policy);
  };

  const handleActionsMenuClose = () => setActionsMenuAnchor(null);

  const handleCheckInPolicyArchiveActionClick = () => {
    setIsArchiveDialogOpen(true);
    handleActionsMenuClose();
  };

  const handleArchiveDialogClose = () => setIsArchiveDialogOpen(false);

  const handleArchiveSuccess = () => {
    setIsArchiving(false);
    setIsArchiveDialogOpen(false);
    dispatch(snackAdd({ message: 'Check-in policy successfully archived.', type: 'success' }));
  };

  const handleArchiveFailed = () => {
    setIsArchiving(false);
    dispatch(snackAdd({ message: 'Error archiving check-in policy.', type: 'error' }));
  };

  const handleArchiveCheckInPolicy = () => {
    setIsArchiving(true);
    dispatch(
      checkInPolicyArchive({
        onFailed: handleArchiveFailed,
        onSuccess: handleArchiveSuccess,
        variables: {
          input: {
            checkInPolicy: {
              id: currentlySelectedPolicy?.id || '',
            },
            organization: {
              id: organizationId || '',
            },
          },
        },
      }),
    );
  };

  const handleCheckInPolicyEditActionClick = () => {
    onEditActionClick(currentlySelectedPolicy?.id || '');
  };

  const checkInPoliciesMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [
    [
      {
        key: 'edit-policy',
        dataTestId: makeTestId('edit-policy'),
        onClick: handleCheckInPolicyEditActionClick,
        label: 'Edit',
        handleCloseMenu: handleActionsMenuClose,
        tooltip: 'Edit Check-In policy',
      },
      {
        key: 'archive-check-in-policy',
        dataTestId: makeTestId('archive-check-in-policy'),
        onClick: handleCheckInPolicyArchiveActionClick,
        label: 'Archive',
        handleCloseMenu: handleActionsMenuClose,
        tooltip: 'Archive Check-In policy',
      },
    ],
  ];

  const archiveDialog = (
    <ArchiveDialog
      dataTestId="CheckInPolicies"
      handleConfirmClick={handleArchiveCheckInPolicy}
      isArchiving={isArchiving}
      resourceName={currentlySelectedPolicy?.name || ''}
      title="Archive Check-In Policy"
      onClose={handleArchiveDialogClose}
    />
  );

  const onCheckInDisconnectSuccess = () => {
    dispatch(snackAdd({ message: 'Check-In bot disconnected successfully', type: 'success' }));
    navigate('..');
  };
  const onCheckInDisconnectFailed = () =>
    dispatch(snackAdd({ message: 'Check-In bot disconnection failed', type: 'error' }));

  const handleDisableClick = () => {
    dispatch(
      appDisconnect({
        onSuccess: onCheckInDisconnectSuccess,
        onFailed: onCheckInDisconnectFailed,
        variables: {
          appId: app?.node.id || '',
          organizationId,
        },
      }),
    );
  };

  if (isLoadingCheckInPolicies) return <CheckInAppDrawerSkeleton />;

  return (
    <>
      {isArchiveDialogOpen ? archiveDialog : null}
      {actionsMenuAnchorEl && shouldDisplayActionsMenu && (
        <BasicMenu
          key="basic-menu"
          keepMounted
          anchorEl={actionsMenuAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openMenuAnchor}
          sections={checkInPoliciesMenuSections}
          onClose={handleActionsMenuClose}
        />
      )}
      <Drawer.Header loading={isCurrentAppDisconnecting || isFetchingCheckInPolicies}>
        <Typography noWrap variant="h6">
          {app?.node.displayName}
        </Typography>
      </Drawer.Header>
      <Drawer.Content sx={drawerContentSx}>
        <Typography sx={horizontalPaddingSx}>
          Streamline your check-in’s with Serenity's check-in bot. It works hand in hand with
          messaging and Alexa to simplify the check-in process.
        </Typography>
        <Box sx={[disableButtonWrapperSx, horizontalPaddingSx]}>
          <Button
            color="error"
            startIcon={<CancelRounded />}
            variant="outlined"
            onClick={handleDisableClick}
          >
            Disable
          </Button>
        </Box>
        <Typography sx={horizontalPaddingSx} variant="overline">
          Check in's
        </Typography>
        <Divider sx={dividerSx} />
        {noCheckInPolicies && !isLoadingCheckInPolicies ? (
          <Box sx={emptyStateWrapperSx}>
            <Box sx={iconWrapperSx}>
              <CheckCircle sx={iconSx} />
            </Box>
            <Typography sx={{ color: 'text.secondary' }} variant="subtitle1m">
              No Check-In's
            </Typography>
            <Typography sx={typographySx} variant="body2">
              No check-ins yet? Start by creating your first one!
            </Typography>
          </Box>
        ) : (
          <List sx={listSx}>
            {checkInPolicies.map((policy) => {
              return (
                <ListItemButton
                  key={policy.id}
                  disableGutters
                  sx={horizontalPaddingSx}
                  onClick={() => onEditActionClick(policy.id)}
                >
                  <ListItemText
                    primary={policy.name}
                    secondary={`Runs ${policy.schedules.length}x daily`}
                  />
                  <IconButton
                    aria-label="checkIn-item-menu"
                    edge="end"
                    onClick={(event) => handleActionsMenuClick(policy, event)}
                  >
                    <MoreVert />
                  </IconButton>
                </ListItemButton>
              );
            })}
          </List>
        )}
        {/* before we can support multiple policies the 'Add' button will be hidden if one policy already exists */}
        {checkInPolicies.length === 0 && (
          <Box sx={addButtonWrapperSx}>
            <Button
              data-testid={makeTestId(`add`)}
              startIcon={<Add />}
              variant="contained"
              onClick={onAddClick}
            >
              Add
            </Button>
          </Box>
        )}
      </Drawer.Content>
    </>
  );
};

const drawerContentSx = {
  pt: 3,
  // to override style form DrawerContent component
  pl: {
    sm: 0,
    xs: 0,
  },
  pr: {
    sm: 0,
    xs: 0,
  },
};

const horizontalPaddingSx = {
  pl: {
    sm: 3,
    xs: 2,
  },
  pr: {
    sm: 3,
    xs: 2,
  },
};

const dividerSx = {
  ml: {
    sm: 3,
    xs: 2,
  },
  mr: {
    sm: 3,
    xs: 2,
  },
};

const emptyStateWrapperSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  py: 2,
  px: 3,
};

const iconSx = {
  width: 60,
  height: 60,
  color: 'action.disabled',
};

const iconWrapperSx = {
  display: 'flex',
  width: 120,
  height: 120,
  bgcolor: 'neutral.light',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
};

const typographySx = {
  maxWidth: 260,
  height: 46,
  textAlign: 'center',
};

const addButtonWrapperSx = {
  display: 'flex',
  justifyContent: 'center',
};

const listSx = {
  pt: 3,
};

const disableButtonWrapperSx = { justifyContent: 'flex-end', display: 'flex', pb: 3 };

export default CheckInAppDetailDrawer;
