import * as Sentry from '@sentry/react';
import { ResolveInfo, Cache, DataFields } from '@urql/exchange-graphcache';

import { Schema } from '@serenityapp/domain';
import { addLocation, removeLocations } from './utils';
import {
  organizationLocationGroupsQuery,
  OrganizationLocationGroupsResult,
} from '@serenityapp/api-graphql';

// handles location group to building conversion mutation result in urql cache
export default function locationGroupBuildingConvert(
  result: DataFields,
  _args: Schema.LocationGroup.BuildingConvert.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const locationGroupBuildingConvertOutput =
    result?.result as Schema.LocationGroup.BuildingConvert.Output;

  if (locationGroupBuildingConvertOutput.success) {
    const { organizationId, id, name } = locationGroupBuildingConvertOutput.item;
    const { id: locationGroupId } = _args.input;

    const location: Schema.Location.ItemWithTypename = {
      id,
      name,
      kind: 'Building',
      displayName: 'Building',
      __typename: 'Building',
    };

    const q = {
      query: organizationLocationGroupsQuery,
      variables: { getInput: { id: organizationId } },
    };

    // clear up location group from location groups query
    cache.updateQuery<OrganizationLocationGroupsResult>(q, (data) => {
      if (!data) {
        Sentry.addBreadcrumb({
          message:
            'Failed to get current organization location query data while trying to remove a location',
          data: { q },
        });
        return null;
      }

      const cachedEdges = data.result.locationGroups.edges;

      const updatedEdges = cachedEdges.filter(
        (edge) => edge.locationGroup.id === locationGroupId,
      );

      // If no changes were made, return the original data
      if (updatedEdges.length === cachedEdges.length) {
        return data;
      }

      data.result.locationGroups.edges = updatedEdges;
      return data;
    });

    addLocation(location, organizationId, cache);
    removeLocations([locationGroupId], organizationId, cache);
  }
}
