import { SyntheticEvent, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { Divider, Typography } from '@mui/material';

import { FormikField, SelectField } from '@serenityapp/components-react-web';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevel } from '@serenityapp/core';

import { transformServiceLevelsToSelectorItemProps } from '../../../common/utils';
import { MULTI_PURPOSE_SERVICE_LEVEL } from '../utils';
import { UnitFormValues } from '../schema';
import { useBuildings, useFloors, useLocationGroups, useUsers } from '../hooks';

import AddressFields from './AddressFields';
import AutocompleteField from './AutocompleteField';
import ServiceLevelMismatchDialog from './ServiceLevelMismatchDialog';
import UserSearchSelectorField from './UserSearchSelectorField';
import { dividerSx } from './styles';

const serviceLevels: SelectorItemProps[] = transformServiceLevelsToSelectorItemProps(
  Object.values(ServiceLevel),
).concat([{ id: MULTI_PURPOSE_SERVICE_LEVEL, label: 'Multi-purpose' }]);

type UnitFieldsProps = {
  isEditMode: boolean;
  setIsDataLoading: (value: boolean) => void;
};

const UnitFields = ({ isEditMode = false, setIsDataLoading }: UnitFieldsProps) => {
  const { setFieldValue, initialValues } = useFormikContext<UnitFormValues>();

  const { floorId, buildingId, pccRecordStamp, locationGroupId } = initialValues as FormikValues;

  const [selectedLocationGroupId, setSelectedLocationGroupId] = useState(locationGroupId);
  const [selectedBuildingId, setSelectedBuildingId] = useState(buildingId);
  const [selectedFloorId, setSelectedFloorId] = useState(floorId);

  const { locationGroupOptions, locationGroupsFetching, locationGroupBuildingsSelectItems } =
    useLocationGroups(selectedLocationGroupId);

  useEffect(() => {
    if (initialValues) {
      setSelectedLocationGroupId(locationGroupId);
      setSelectedBuildingId(buildingId);
      setSelectedFloorId(floorId);
    }
  }, [initialValues, locationGroupId, buildingId, floorId]);

  // Buildings logic
  const { buildingsFetching, buildingOptions, selectedBuilding } =
    useBuildings(selectedBuildingId);

  // Floors logic
  const { floorsOptions } = useFloors(selectedBuilding);

  // Fetch users
  const { usersFetching, usersOptions } = useUsers();

  useEffect(() => {
    setIsDataLoading(buildingsFetching || usersFetching || locationGroupsFetching);
  }, [buildingsFetching, usersFetching, locationGroupsFetching, setIsDataLoading]);

  const selectedBuildingItem = buildingOptions.find(
    (item: SelectorItemProps) => item.id === selectedBuildingId,
  );

  const selectedFloorItem = floorsOptions.find(
    (item: SelectorItemProps) => item.id === selectedFloorId,
  );

  const selectedLocationGroupItem: SelectorItemProps | undefined = locationGroupOptions.find(
    (item: SelectorItemProps) => item.id === selectedLocationGroupId,
  );

  const clearFloorInput = () => {
    setSelectedFloorId(undefined);
    setFieldValue('floor', null);
    setFieldValue('floorId', null);
  };

  const clearBuildingInput = () => {
    setFieldValue('building', null);
    setFieldValue('buildingId', null);
    setSelectedBuildingId(undefined);
  };

  const clearLocationGroupInput = () => {
    setFieldValue('locationGroup', null);
    setFieldValue('locationGroupId', null);
    setSelectedLocationGroupId(undefined);
  };

  const handleLocationGroupChange = (
    event: SyntheticEvent,
    value: SelectorItemProps,
    reason: string,
  ) => {
    if (reason === 'clear') {
      clearFloorInput();
      clearBuildingInput();
      clearLocationGroupInput();

      return;
    }
    setFieldValue('locationGroup', value);
    setFieldValue('locationGroupId', value?.id);
    setSelectedLocationGroupId(value?.id || null);

    // clear up building and floor inputs
    clearBuildingInput();
    clearFloorInput();
  };

  const handleBuildingChange = (
    event: SyntheticEvent,
    value: SelectorItemProps,
    reason: string,
  ) => {
    if (reason === 'clear') {
      clearFloorInput();
      clearBuildingInput();
      return;
    }

    setFieldValue('building', value);
    setFieldValue('buildingId', value?.id);
    setSelectedBuildingId(value?.id || null);
    clearFloorInput();
  };

  const handleFloorChange = (event: SyntheticEvent, value: SelectorItemProps, reason: string) => {
    if (reason === 'clear') {
      clearFloorInput();
      return;
    }

    setFieldValue('floor', value);
    setFieldValue('floorId', value?.id);
    setSelectedFloorId(value?.id || null);
  };

  return (
    <>
      {!buildingsFetching && !locationGroupsFetching && (
        <>
          {isEditMode && pccRecordStamp && (
            <FormikField
              disabled
              label="PointClickCare record type"
              name="pccRecordStamp"
              type="text"
            />
          )}
          <FormikField
            helperText="Overwrite default location type name"
            label="Display name"
            name="displayName"
            type="text"
          />
          <SelectField items={serviceLevels} label="Service level" name="serviceLevel" />
          <AutocompleteField
            items={locationGroupOptions}
            label="Location Group"
            name="locationGroup"
            value={selectedLocationGroupItem || null}
            onChange={handleLocationGroupChange}
          />
          <AutocompleteField
            items={
              locationGroupBuildingsSelectItems?.length === 0 && !selectedLocationGroupItem
                ? buildingOptions
                : locationGroupBuildingsSelectItems
            }
            label="Building"
            name="building"
            value={selectedBuildingItem || null}
            onChange={handleBuildingChange}
          />
          <AutocompleteField
            items={floorsOptions}
            label="Floor"
            name="floor"
            value={selectedFloorItem || null}
            onChange={handleFloorChange}
          />
          <Typography display="block" variant="overline">
            residents
          </Typography>
          <Divider sx={dividerSx} />
          <UserSearchSelectorField usersOptions={usersOptions} />
          <AddressFields />
          <ServiceLevelMismatchDialog />
        </>
      )}
    </>
  );
};

export default UnitFields;
