import { Typography, Box, Divider } from '@mui/material';
import { FormikField } from '@serenityapp/components-react-web';
import { dividerSx } from './styles';

const AddressFields = () => {
  return (
    <>
      <Typography display="block" sx={sectionHeaderSx} variant="overline">
        location address
      </Typography>
      <Divider sx={dividerSx} />
      <FormikField label="Country/Region" name="countryOrRegion" type="text" />
      <FormikField helperText="Street address" label="Address" name="addressLine1" type="text" />
      <FormikField
        label="Apt, suite, building, unit, floor, etc."
        name="addressLine2"
        type="text"
      />
      <Box sx={horizontalFieldsWrapperSx}>
        <FormikField label="City" name="city" type="text" />
        <FormikField label="State" name="stateOrRegion" type="text" />
        <FormikField label="ZIP Code" name="postalCode" type="text" />
      </Box>
    </>
  );
};

const horizontalFieldsWrapperSx = { display: 'flex', justifyContent: 'space-between', gap: 2 };
const sectionHeaderSx = { mt: 3 };

export default AddressFields;
