import { Cache, DataFields, ResolveInfo } from '@urql/exchange-graphcache';
import { Schema } from '@serenityapp/domain';
import { removeLocations } from './utils';

export default function buildingRemove(
  result: DataFields,
  _args: Schema.Building.Remove.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const removedBuildingOutput = result?.result as Schema.Building.Remove.Output;
  if (removedBuildingOutput.success) {
    const { organizationId } = removedBuildingOutput.item;
    const locationsToRemove = removedBuildingOutput.deletedLocationIds;

    removeLocations(locationsToRemove, organizationId, cache);
  }
}
