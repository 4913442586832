import { default as emojiRegularExpression } from 'emoji-regex';
import { TextNode } from 'lexical';
import { useEffect } from 'react';
import * as R from 'ramda';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import data from '@emoji-mart/data/sets/14/google.json';
import { Emoji } from '@emoji-mart/data';

import EmojiNode, { $createEmojiNode } from '../nodes/EmojiNode';
import { EmojiObject } from '../types';
import { SKIN_TONE_DEFAULT, getSkinToneId } from './utils';

const EXCEPTION_SHORTCODES_THUMBSUP = ['thumbsup', 'thumbs-up', 'thumbs_up'];
const THUMBSUP_EMOJI_ID = '+1';

// Function to escape special characters in a string for regex
const escapeRegExp = (string: string) => {
  return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
};

// EMOTICON REGEX
// Create a regex pattern from the array of emoticons
const getEmoticonRegex = () => {
  if (!data) return null;

  const dataEmoticons = Object.keys((data as any).emoticons);
  // **NOTE 1**
  // RegEx for emoticons matches:
  //   - space or beginning of the line
  //   - emoticon
  //   - space
  // The reason is that in editable editor we need to be able to predict on whether the user
  // wants to write emoticon or is trying to write a text that includes the same symbols as
  // emoticon. For ex. user wants to write shortcode for "pray" emoji, so s/he starts writing
  // ":p". If in this case we did not have a regular expression that expects space after emoji,
  // this would get transformed right away into emoji and user won't ever be able to write what
  // s/he was attempting to (:pray:).
  const emoticonRegex = new RegExp(
    `(^|\\s)(${dataEmoticons.map((emoticon) => escapeRegExp(emoticon)).join('|')})\\s`,
    'g',
  );

  return emoticonRegex;
};

// SHORTCODES REGEX
// Create a regex pattern from the array of emoticons
const getShortcodesRegex = () => {
  if (!data) return null;

  const emojiMartShortcodes = Object.keys(data.emojis);
  // emoji-mart defines shortcodes with underscores only. Users are used to from other
  // applications to be able to write shortcodes with minus symbol as well.
  // :ok_hand: vs. :ok-hand:
  // With this piece of code we are adding all emoji shortcodes that include underscore symbols
  // in a form of minus symbol additionally.
  const additionalShortcodeFormats: Array<string> = R.reduce(
    (acc, shortcode) => {
      if (shortcode.includes('_')) {
        return [...acc, shortcode.replace('_', '-')];
      }
      return acc;
    },
    [] as Array<string>,
    emojiMartShortcodes,
  );

  const allShortcodes = [
    ...emojiMartShortcodes,
    ...additionalShortcodeFormats,
    ...EXCEPTION_SHORTCODES_THUMBSUP,
  ];
  const shortcodesRegex = new RegExp(`:(${allShortcodes.map(escapeRegExp).join('|')}):`, 'g');

  return shortcodesRegex;
};

// EMOJI REGEX
const getUnicodeRegex = () => {
  const emojiRegex = emojiRegularExpression();
  return emojiRegex;
};

// A matcher that matches native emojis in text such as 😀 😁 🤣. Based on that emoji glyph it
// finds related data within `emoji-mart` data and returns a matcher object containing that emoji
// object.
const unicodeEmojiMatcher = (text: string): EmojiMatcherResult | null => {
  const regex = getUnicodeRegex();
  // Execute emoji regex on provided string
  const match = regex.exec(text);

  // If no match is found we return null
  if (match === null) return null;

  const fullMatch = match[0];

  // Find emoji id within `emoji-mart` data using matched unicode.
  const nativeEmojiID = (data as any).natives[fullMatch];

  // If the emoji id is not found by matched unicode, we return null.
  if (!nativeEmojiID) return null;

  // Take emoji id and find its related data within emojis map from `emoji-mart` data
  const emoji = (data as any).emojis[nativeEmojiID];

  // If an emoji is not found within `emoji-mart` data, we return null.
  if (!emoji) return null;

  return {
    index: match.index,
    length: fullMatch.length,
    text: fullMatch,
    emoji: emoji,
    // Since we have a unicode of the emoji match we can also derive its skin tone id from it.
    skinToneId: getSkinToneId(fullMatch),
  };
};

// A matcher that matches shortcodes in text such as :smile: or :+1:. Based on that shortcode
// this function finds related data within `emoji-mart` data and returns a matcher object
// containing the emoji object.
const shortcodeEmojiMatcher = (text: string): EmojiMatcherResult | null => {
  const regex = getShortcodesRegex();

  // If no regex is available
  if (regex === null) return null;

  // Execute regex pattern on provided string
  const match = regex.exec(text);

  // If no match is found we return null
  if (match === null) return null;

  const fullMatch = match[0];
  const shortcodeMatch = match[1];

  // Get first capturing group that contains emoji shortcode without columns.
  // Emoji shortcode represents emoji id as well, so we use it here to define emoji id.
  // **NOTE 1** Users are used to writing shortcode for thumbsup emoji in a form of using the
  // whole word :thumbsup:. This is, however, not recognizable in emoji-mart since this emoji is
  // known as "+1" emoji only there. That is why we are adding an exception for the emoji and
  // are manually mapping it to the right emoji id.
  // **NOTE 2** emoji-mart data includes shortcodes with underscore symbol only `:ok_hand:`, but
  // we provide an ability for user to enter it in form of minus symbol `:ok-hand:`. For this
  // reason we first need to replace all minus symbols into underscores, so that emoji-mart data
  // recognizes it.
  const nativeEmojiID = EXCEPTION_SHORTCODES_THUMBSUP.includes(shortcodeMatch)
    ? THUMBSUP_EMOJI_ID
    : shortcodeMatch.includes('-')
      ? shortcodeMatch.replace('-', '_')
      : shortcodeMatch;

  // Take emoji id and find its related data within emojis map from `emoji-mart` data
  // **Note** I casted data to any since its types does not match what it actually is
  // When I console.log data it returns object with emoticons, but the type of data does not
  // define emoticons. It is well known that `emoji-mart` still has types issues.
  const emoji = (data as any).emojis[nativeEmojiID];

  // If an emoji is not found within `emoji-mart` data, we return null.
  if (!emoji) return null;

  // Check if localStorage contains skin tone id
  const skinToneIdLocalStorage = localStorage.getItem('emoji-mart.skin');

  // Check if skin tone can be applied to emoji. Some emojis like :dog: does not have a variants
  // based on skin tone. In that case skin tone should always have default value.
  const isSkinToneApplicable = emoji.skins && emoji.skins.length > 1;
  const skinToneId =
    isSkinToneApplicable && skinToneIdLocalStorage
      ? Number(skinToneIdLocalStorage) - 1
      : SKIN_TONE_DEFAULT;

  // If emoji is found we return a matcher object containing the emoji data itself.
  return {
    index: match.index,
    length: fullMatch.length,
    text: fullMatch,
    emoji,
    skinToneId,
  };
};

// A matcher that matches emoticons in text such as :), ;), <3, -_-, >:-(. Based on that emoticon
// this function finds related data within `emoji-mart` data and returns a matcher object
// containing the emoji object.
const emoticonEmojiMatcher = (text: string): EmojiMatcherResult | null => {
  const regex = getEmoticonRegex();

  // If no regex is available
  if (regex === null) return null;

  // Execute regex on provided string
  const match = regex.exec(text);

  // If no match is found we return null
  if (match === null) return null;

  const fullMatch = match[0];
  const emoticonMatch = match[2];

  // Find emoji id within `emoji-mart` data using matched emoticon.
  // **NOTE 1** I casted data to any since its types does not match what it actually is.
  // When I console.log data it returns object with emoticons, but the type of data does not
  // define emoticons. It is well known that `emoji-mart` still has types issues.
  // **NOTE 2** emoji-mart data has a map of all emoticons to ids. For '<3' emoticon key in map
  // it has a "purple_heart" id. This is wrong. It should default to "heart" only.
  // For that reason we need to make an exception in a way for finding an emoji id for heart.
  const nativeEmojiID = emoticonMatch === '<3' ? 'heart' : (data as any).emoticons[emoticonMatch];

  // If the emoji id is not found by matched emoticon, we return null.
  if (!nativeEmojiID) return null;

  // Take emoji id and find its related data within emojis map from `emoji-mart` data.emojis
  const emoji = (data as any).emojis[nativeEmojiID];

  // If an emoji is not found within `emoji-mart` data, we return null.
  if (!emoji) return null;

  const hasSpaceAtMatchBeginning = fullMatch[0] === ' ';

  // If an emoji is found we return a matcher object containing the emoji data itself.
  // **NOTE** We are matching emoticons with spaces to make sure user actually
  // wants to end emoticons and does not want to continue writing ex. shortcode. (:p vs. :pray:).
  // When transforming the match to actual emoji node, we do not want to remove spaces before
  // and after it. For that reason we are making sure to change the match index and match length
  // to represent emoticon only (to keep spaces).
  return {
    index: hasSpaceAtMatchBeginning ? match.index + 1 : match.index,
    length: emoticonMatch.length,
    text: emoticonMatch,
    emoji: emoji,
    isEmoticon: true,
  };
};

const MATCHERS = [unicodeEmojiMatcher, emoticonEmojiMatcher, shortcodeEmojiMatcher];

type EmojiMatcherResult = {
  index: number;
  length: number;
  text: string;
  emoji: Emoji;
  skinToneId?: number;
  isEmoticon?: boolean;
};

type EmojiMatcher = (text: string) => EmojiMatcherResult | null;

/**
 * A function that loops thru the provided matcher functions and finds first match in the text
 * that was matched by any of the provided matchers
 * @param text A text to search emojis in
 * @param matchers Array of matcher functions to match emojis
 * @returns null | EmojiMatchResult A match or null if no match was found
 */
const findFirstMatch = (
  text: string,
  matchers: Array<EmojiMatcher>,
): EmojiMatcherResult | null => {
  for (let i = 0; i < matchers.length; i++) {
    const match = matchers[i](text);
    if (match) return match;
  }

  return null;
};

/**
 * A function that takes in TextNode, finds all emoji matches within the text node, splits up the
 * text by emoji matches, replaces the emoji matches with EmojiNodes and saves all changes into
 * editor's state.
 * @param node pure TextNode
 * @param matchers array of matcher functions to match emojis
 */
const handleEmojiCreation = (node: TextNode, matchers: Array<EmojiMatcher>) => {
  const nodeText = node.getTextContent();

  let text = nodeText;
  let remainingTextNode = node;
  let match;

  while ((match = findFirstMatch(text, matchers)) && match !== null) {
    const matchStart = match.index;
    const matchLength = match.length;
    const matchEnd = matchStart + matchLength;

    let emojiTextNode;

    if (matchStart === 0) {
      [emojiTextNode, remainingTextNode] = remainingTextNode.splitText(matchLength);
    } else {
      [, emojiTextNode, remainingTextNode] = remainingTextNode.splitText(matchStart, matchEnd);
    }

    const skinToneId = match.skinToneId || SKIN_TONE_DEFAULT;
    const emojiSkinById = match.emoji.skins[skinToneId];

    const emojiObject: EmojiObject = {
      id: match.emoji.id,
      native: emojiSkinById.native,
      unified: emojiSkinById.unified,
      name: match.emoji.name,
      skin: skinToneId,
      keywords: match.emoji.keywords,
      emoticons: match.emoji.emoticons,
      x: emojiSkinById.x || 0,
      y: emojiSkinById.y || 0,
    };

    const emojiNode = $createEmojiNode(emojiObject, emojiObject.native);
    emojiTextNode.replace(emojiNode);

    text = text.substring(matchEnd);
  }
};

/**
 * A plugin that is used to replace all emoji glyphs found in text into emoji nodes.
 * Registers a listener that will run when a text node is marked dirty during an update.
 * @returns null
 */
export default function AutoEmojiPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([EmojiNode])) {
      throw new Error('AutoEmojiPlugin: EmojiNode not registered on editor');
    }

    return editor.registerNodeTransform(TextNode, (textNode: TextNode) => {
      handleEmojiCreation(textNode, MATCHERS);
    });
  }, [editor]);

  return null;
}
