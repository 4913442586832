import { Schema } from '@serenityapp/domain';
import { Cache, DataFields, ResolveInfo } from '@urql/exchange-graphcache';
import { addLocation } from './utils';

export default function locationGroupCreate(
  result: DataFields,
  _args: Schema.LocationGroup.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdLocationGroupOutput = result?.result as Schema.LocationGroup.Create.Output;

  if (createdLocationGroupOutput.success) {
    const { organizationId, id, name } = createdLocationGroupOutput.item;

    const location: Schema.Location.ItemWithTypename = {
      id,
      name,
      kind: 'LocationGroup',
      displayName: 'Location Group',
      __typename: 'LocationGroup',
    };

    addLocation(location, organizationId, cache);
  }
}
