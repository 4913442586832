import { ChangeEvent, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormikProps, FormikValues } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Typography, Chip } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import { FormikField, Form, Drawer, SelectField } from '@serenityapp/components-react-web';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';
import { Location } from '@serenityapp/api-graphql';

import FormikDrawer from '../../components/FormikDrawer';
import { validationSchema } from '../schema';
import { locationTypes, validateWithZod, getLocationTypeLabelById } from '../utils';

import { dividerSx, headerInfoSx } from './styles';
import BuildingFields from './BuildingFields';
import UnitFields from './UnitFields';
import LocationActionsMenu from '../LocationsActionMenu';
import ConfirmTypeConversionDialog from './ConfirmTypeConversionDialog';

type LocationFormProps = {
  initialValues: FormikValues;
  location?: Location.LocationApi.Location;
  isFetching: boolean;
  editMode?: boolean;
  isLoading: boolean;
  onFormSubmit: (values: FormikValues) => void;
};

const LocationForm = ({
  initialValues,
  isFetching,
  location,
  isLoading,
  editMode = false,
  onFormSubmit,
}: LocationFormProps) => {
  const navigate = useNavigate();
  const isPCCFeatureEnabled = useIsFeatureEnabled('pcc');

  const [isLoadingData, setIsDataLoading] = useState(false);

  const [shouldOpenTypeConversionWarning, setShouldOpenTypeConversionWarning] = useState(false);
  const [locationTypeToChange, setLocationTypeToChange] = useState(initialValues.locationType);

  const shouldDisplayActionsMenu = editMode;

  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setActionsMenuAnchor(event.currentTarget);

  const goBack = () => {
    navigate('..');
  };

  const onClose = useCallback(() => {
    navigate('..');
  }, [navigate]);

  const closeActionsMenu = () => {
    setActionsMenuAnchor(null);
  };

  const shouldAllowConversion =
    (initialValues.locationType === 'Building' ||
      initialValues.locationType === 'LocationGroup') &&
    isPCCFeatureEnabled;

  const renderDrawerBody = ({
    submitForm,
    setFieldValue,
    setValues,
    values,
    dirty,
    isValid,
  }: FormikProps<FormikValues>) => {
    const enableSave = dirty && isValid;

    const locationTypesItems = shouldAllowConversion
      ? locationTypes.map((locationType) => {
          if (editMode && isPCCFeatureEnabled && locationType.id === 'Unit')
            return {
              ...locationType,
              disabled: true,
              tooltip: `${values.locationType} cannot be converted to Unit`,
            };
          return locationType;
        })
      : locationTypes;

    const renderLocationTypeFields = () => {
      switch (values.locationType) {
        case 'Building':
          return <BuildingFields setIsDataLoading={setIsDataLoading} />;
        case 'Unit':
          return <UnitFields isEditMode={editMode} setIsDataLoading={setIsDataLoading} />;
        default:
          return null;
      }
    };

    const handleLocationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (editMode && isPCCFeatureEnabled) {
        setShouldOpenTypeConversionWarning(true);
      }
      setLocationTypeToChange(values.locationType);
      setValues((prevValues) => ({
        ...prevValues,
        locationType: event.target.value,
        displayName: '',
      }));
    };

    const handleCancelingTypeConversion = () => {
      // reset to initial location type
      setFieldValue('locationType', locationTypeToChange);
      setShouldOpenTypeConversionWarning(false);
    };

    return (
      <>
        <Drawer.Header loading={isFetching || isLoading || isLoadingData}>
          <Box sx={headerInfoSx}>
            <Typography noWrap variant="h6">
              {editMode ? `Edit location ${initialValues?.locationName}` : 'New location'}
            </Typography>
            {editMode && values.locationType && (
              <Chip label={initialValues?.locationType} size="small" />
            )}
          </Box>
          {shouldDisplayActionsMenu && (
            <>
              <Button
                color="neutral"
                disabled={isLoading}
                endIcon={<KeyboardArrowDown sx={actionsButtonIconSx} />}
                sx={actionsButtonSx}
                onClick={openActionsMenu}
              >
                Actions
              </Button>
              <Divider orientation="vertical" sx={headerDividerSx} />
            </>
          )}
        </Drawer.Header>
        <Drawer.Content>
          <Form disabled={isFetching}>
            <Typography display="block" variant="overline">
              general
            </Typography>
            <Divider sx={dividerSx} />
            <FormikField label="Location name" name="locationName" type="text" />
            <SelectField
              displayTooltips
              disabled={editMode && !shouldAllowConversion}
              items={locationTypesItems}
              label="Location type"
              name="locationType"
              onChange={handleLocationTypeChange}
            />
            {renderLocationTypeFields()}
          </Form>
        </Drawer.Content>
        <Drawer.Footer>
          <Button onClick={goBack}>Close</Button>
          <LoadingButton
            disabled={!enableSave}
            loading={isLoading || isFetching}
            variant="contained"
            onClick={submitForm}
          >
            Save
          </LoadingButton>
        </Drawer.Footer>
        {shouldOpenTypeConversionWarning && (
          <ConfirmTypeConversionDialog
            id={values.id}
            isOpen={shouldOpenTypeConversionWarning}
            oldLocationType={getLocationTypeLabelById(locationTypeToChange)}
            newLocationType={getLocationTypeLabelById(values.locationType)}
            location={location as Location.LocationApi.LocationGroupWithTypename}
            onCancel={handleCancelingTypeConversion}
            onClose={() => setShouldOpenTypeConversionWarning(false)}
          />
        )}
      </>
    );
  };

  return (
    <>
      <LocationActionsMenu
        anchorEl={actionsMenuAnchor}
        locationId={initialValues.id}
        onActionSuccess={goBack}
        onClose={closeActionsMenu}
      />
      <FormikDrawer
        shouldConfirmClose
        initialValues={initialValues}
        validateForm={validateWithZod(validationSchema)}
        onClose={onClose}
        onSubmit={onFormSubmit}
      >
        {renderDrawerBody}
      </FormikDrawer>
    </>
  );
};

const actionsButtonSx = {
  color: 'text.primary',
  textTransform: 'capitalize',
  mr: 1,
};

const actionsButtonIconSx = {
  color: 'action.active',
};

const headerDividerSx = {
  height: '40px',
};

export default LocationForm;
