import { useMemo } from 'react';
import { UseQueryArgs, useQuery } from 'urql';

import { Schema } from '@serenityapp/domain';
import {
  OrganizationBuildingsResult,
  organizationBuildingsQuery,
} from '@serenityapp/api-graphql';
import { SelectorItemProps } from '@serenityapp/components-react-common';

import { useCurrentUser } from '../../../common/hooks';

type UseBuildingsReturnType = {
  buildings?: Schema.Organization.Buildings.Connection;
  buildingOptions: SelectorItemProps[];
  buildingsFetching: boolean;
  selectedBuilding?: Schema.Organization.Buildings.Edge;
};

/**
 * This hook is returns the buildings of the current organization, buildings transformed to select items
 * and the selected building object by its ID.
 *
 * @param selectedBuildingId
 */
const useBuildings = (selectedBuildingId?: string): UseBuildingsReturnType => {
  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId || '';

  const buildingsQueryOptions: UseQueryArgs<
    Schema.Organization.Buildings.Variables,
    OrganizationBuildingsResult
  > = useMemo(
    () => ({
      query: organizationBuildingsQuery,
      requestPolicy: 'cache-and-network',
      variables: { getInput: { id: organizationId } },
      pause: !organizationId,
    }),
    [organizationId],
  );

  const [buildingsResult] = useQuery(buildingsQueryOptions);
  const { data, fetching: buildingsFetching } = buildingsResult;

  const buildings = data?.result?.buildings;

  // Transform building data to select items
  const buildingOptions = useMemo(
    () =>
      buildings?.edges?.map((edge: Schema.Organization.Buildings.Edge) => ({
        id: edge.building.id,
        label: edge.building.name,
      })) || [],
    [buildings],
  );

  const selectedBuilding = useMemo(
    () =>
      buildings?.edges?.find(
        (edge: Schema.Organization.Buildings.Edge) => edge.building.id === selectedBuildingId,
      ),
    [buildings?.edges, selectedBuildingId],
  );

  return useMemo(
    () => ({ buildings, buildingOptions, buildingsFetching, selectedBuilding }),
    [buildings, buildingOptions, buildingsFetching, selectedBuilding],
  );
};

export default useBuildings;
