import { Schema } from '@serenityapp/domain';
import { Cache, DataFields, ResolveInfo } from '@urql/exchange-graphcache';
import { addLocation } from './utils';

export default function buildingCreate(
  result: DataFields,
  _args: Schema.Unit.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdUnitOutput = result?.result as Schema.Unit.Create.Output;

  if (createdUnitOutput.success) {
    const { organizationId, displayName, id, name } = createdUnitOutput.item;

    const location: Schema.Location.ItemWithTypename = {
      id,
      displayName: displayName ?? 'Unit',
      name,
      kind: 'Unit',
      __typename: 'Unit',
    };

    addLocation(location, organizationId, cache);
  }
}
