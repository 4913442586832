import * as Sentry from '@sentry/react';
import { Schema } from '@serenityapp/domain';
import { DataFields, ResolveInfo, Cache } from '@urql/exchange-graphcache';
import { addLocation, removeLocations } from './utils';
import {
  organizationBuildingsQuery,
  OrganizationBuildingsResult,
} from '@serenityapp/api-graphql';

// handles building to location group conversion mutation result in urql cache
export default function buildingLocationGroupConvert(
  result: DataFields,
  _args: Schema.Building.LocationGroupConvert.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const buildingLocationGroupConvertOutput =
    result?.result as Schema.Building.LocationGroupConvert.Output;
  if (buildingLocationGroupConvertOutput.success) {
    const { organizationId, id, name } = buildingLocationGroupConvertOutput.item;
    const { id: buildingId } = _args.input;

    const location: Schema.Location.ItemWithTypename = {
      id,
      name,
      kind: 'LocationGroup',
      displayName: 'Location Group',
      __typename: 'LocationGroup',
    };

    const q = {
      query: organizationBuildingsQuery,
      variables: { getInput: { id: organizationId } },
    };

    cache.updateQuery<OrganizationBuildingsResult>(q, (data) => {
      if (!data) {
        Sentry.addBreadcrumb({
          message:
            'Failed to get current organization location query data while trying to remove a location',
          data: { q },
        });
        return null;
      }

      const cachedEdges = data.result.buildings.edges;

      const updatedEdges = cachedEdges.filter((edge) => edge.building.id === buildingId);

      // If no changes were made, return the original data
      if (updatedEdges.length === cachedEdges.length) {
        return data;
      }

      data.result.buildings.edges = updatedEdges;
      return data;
    });

    addLocation(location, organizationId, cache);
    removeLocations([buildingId], organizationId, cache);
  }
}
