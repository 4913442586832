import { useMemo } from 'react';

import { Schema } from '@serenityapp/domain';
import { SelectorItemProps } from '@serenityapp/components-react-common';

type UseFloorsReturnType = {
  floorsOptions: SelectorItemProps[];
};

/**
 * This hook is used to transform the floors of the selected building to select items. *
 * @param selectedBuilding
 */
const useFloors = (
  selectedBuilding?: Schema.Organization.Buildings.Edge,
): UseFloorsReturnType => {
  const floorsOptions = useMemo(() => {
    if (!selectedBuilding?.building?.locations?.edges) {
      // if not building is selected in the building select field, no floors are available
      return [];
    }

    const floors = selectedBuilding?.building?.locations?.edges
      .filter((edge: Schema.Building.Locations.Edge) => edge?.location.kind === 'Floor')
      .map((edge: Schema.Building.Locations.Edge) => edge?.location as Schema.Floor.Item);

    const sortedFloors = floors.sort(
      (a: Schema.Floor.Item, b: Schema.Floor.Item) => a.floorNumber - b.floorNumber,
    );

    return sortedFloors.map((location: Schema.Floor.Item) => ({
      id: location.id,
      label: `${location.floorNumber}${location.floorName ? ` ${location.floorName}` : ''}`,
    }));
  }, [selectedBuilding]);

  return { floorsOptions };
};

export default useFloors;
