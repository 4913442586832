import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { EnumFn, IdFn, PhoneNumberFn, ServiceLevel } from '@serenityapp/core';
import { UserCreateEditFormValuesLegacy } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';
import {
  ConversationKind,
  ConversationSubjectKind,
  RouteParamId,
  UserType,
  UserTypeFn,
} from '@serenityapp/domain';
import { snackAdd, userUpdate } from '@serenityapp/redux-store';

import { UserCreateEditFormLegacy } from './components';
import { useUserDetail } from './hooks';
import { useCurrentUser } from '../common/hooks';

const UserEditDrawerLegacy = () => {
  const makeTestId = useMakeTestId('UserEditDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams<RouteParamId>() as RouteParamId;
  const currentUser = useCurrentUser();
  const orgId = currentUser?.orgId;

  const {
    user,
    generalChannels: currentGeneralChannels,
    residentChannels: currentResidentChannels,
    isLoading,
    isUpdating,
  } = useUserDetail(id);

  const initialValues = {
    createAccount: false,
    department: user.department || '',
    description: user.description,
    email: user.email || '',
    fullName: user.fullName,
    generalChannels: currentGeneralChannels,
    jobTitle: user.jobTitle,
    userName: user.name,
    phoneNumber: PhoneNumberFn.getNationalNumber(user.phoneNumber) || '',
    residentChannels: currentResidentChannels,
    userType: user.subsubtype ? user.subsubtype : user.subtype,
    workerCategory: user.workerCategory,
    serviceLevel:
      user.serviceLevel &&
      user.serviceLevel !== ServiceLevel.UNKNOWN &&
      user.serviceLevel !== ServiceLevel.NONE
        ? user.serviceLevel
        : '',
    checkIn: !user.checkInOptOut,
    room: user?.room?.id || undefined,
  };

  const goBack = () => navigate('..');

  const handleFormSubmit = ({
    createAccount,
    department,
    description,
    email,
    familyAndFriends,
    fullName,
    generalChannels,
    jobTitle,
    userName,
    phoneNumber,
    residentChannels,
    staff,
    userType,
    workerCategory,
    serviceLevel,
    checkIn,
    room,
  }: UserCreateEditFormValuesLegacy) => {
    const userKind = UserTypeFn.toKind(userType as UserType);
    const isUserResident = userType === 'resident';
    const isCreateAccount = !isUserResident && createAccount;

    const selectedGeneralChannels = isUserResident ? [] : generalChannels;
    const selectedResidentChannels = isUserResident ? [] : residentChannels;
    const selectedFamilyAndFriends = !isUserResident ? [] : familyAndFriends;
    const selectedStaff = !isUserResident ? [] : staff;

    const residentConversationId = IdFn.new();
    const memberRoleId = IdFn.fromString('member');
    const fullNameSplit = fullName?.trim().split(' ');

    const userToUpdate = {
      id,
      orgId,
      email,
      isCreateAccount,
      phoneNumber: PhoneNumberFn.getInternationalNumber(phoneNumber),
      kind: userKind,
      department: department ? department?.trim() : undefined,
      description: description?.trim(),
      fullName: fullName?.trim(),
      // This legacy component is used when org has `removeUsernames` feature flag disabled.
      // In this case, in form, we only have input fields for `fullName` and `userName`.
      // So, we need to split `fullName` to get `firstName` and `lastName`.
      firstName: fullNameSplit && fullNameSplit[0] ? fullNameSplit[0] : undefined,
      lastName:
        fullNameSplit && fullNameSplit.length > 1
          ? fullNameSplit.slice(1).join(' ').trim()
          : undefined,
      jobTitle: jobTitle ? jobTitle?.trim() : undefined,
      userName: userName?.trim(),
      workerCategory: workerCategory ? workerCategory?.trim() : undefined,
      checkInOptOut: !checkIn,
      serviceLevel: !serviceLevel ? ServiceLevel.NONE : EnumFn.parse(ServiceLevel, serviceLevel),
      roomId: room,
      residentChannels: selectedResidentChannels?.map((item) => ({
        conversationId: item.id,
        kind: ConversationKind.CHANNEL,
        subject: ConversationSubjectKind.RESIDENT,
        roleId: memberRoleId,
        userId: id,
      })),
      staff: selectedStaff?.map((item) => ({
        conversationId: residentConversationId,
        kind: ConversationKind.CHANNEL,
        roleId: memberRoleId,
        subject: ConversationSubjectKind.RESIDENT,
        userId: item.id,
      })),
      familyAndFriends: selectedFamilyAndFriends?.map((item) => ({
        conversationId: residentConversationId,
        kind: ConversationKind.CHANNEL,
        roleId: memberRoleId,
        subject: ConversationSubjectKind.RESIDENT,
        userId: item.id,
      })),
      generalChannels: selectedGeneralChannels?.map((item) => ({
        conversationId: item.id,
        kind: ConversationKind.CHANNEL,
        subject: ConversationSubjectKind.GENERAL,
        roleId: memberRoleId,
        userId: id,
      })),
    };

    dispatch(
      userUpdate({
        variables: { input: userToUpdate },
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error updating user', type: 'error' }));
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'User successfully updated', type: 'success' }));
          goBack();
        },
      }),
    );
  };

  return (
    <UserCreateEditFormLegacy
      isEditMode
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      initialValues={initialValues}
      isLoading={isLoading || isUpdating}
      isSaving={isUpdating}
      title={`Edit user - ${user.fullName}`}
      user={user}
    />
  );
};

export default UserEditDrawerLegacy;
