import { BasicMenu, BasicMenuItemProps } from '@serenityapp/components-react-web';
import { WithKey } from '@serenityapp/domain';
import { useState } from 'react';
import { RoomDigDialog } from './index';

type RoomActionsMenuProps = {
  name: string;
  id: string;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  onActionSuccess?: () => void;
};

export default function RoomActionsMenu({
  name,
  id,
  anchorEl,
  onClose,
}: RoomActionsMenuProps): JSX.Element {
  const openUserActionsMenuAnchor = Boolean(anchorEl);
  const [isDigRoomDialogOpen, setIsDigRoomDialogOpen] = useState<boolean>(false);

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.();
  };

  const roomActionsMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [];

  const handleDiagnosticClick = () => {
    setIsDigRoomDialogOpen(true);
  };

  roomActionsMenuSections.push([
    {
      key: 'dig',
      label: 'Dig Room',
      onClick: handleDiagnosticClick,
    },
  ]);

  return (
    <>
      {anchorEl && (
        <BasicMenu
          key="basic-menu"
          keepMounted
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={openUserActionsMenuAnchor}
          sections={roomActionsMenuSections}
          onClose={handleCloseMenu}
        />
      )}
      {isDigRoomDialogOpen && (
        <RoomDigDialog
          roomId={id}
          roomName={name}
          onClose={() => setIsDigRoomDialogOpen(false)}
        />
      )}
    </>
  );
}
