import { useSelector } from 'react-redux';
import {
  Alert,
  Typography,
  Box,
  Divider,
  MenuItem,
  Menu,
  Button,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useState } from 'react';

import {
  SerenityForm,
  ChannelCreateEditFormConfig,
  ChannelCreateEditFormValues,
} from '@serenityapp/components-react-common';
import {
  Drawer,
  Form,
  FormikField,
  ChipSelectorField,
  useMakeTestId,
  SwitchField,
} from '@serenityapp/components-react-web';
import {
  getAdministerableContactUsersAsSelectorItems,
  getAdministerableLocationsAsSelectorItems,
  getAdministerableStaffUsersAsSelectorItems,
} from '@serenityapp/redux-store';

import ArchiveChannelDialog from './ArchiveChannelDialog';
import ConfirmCloseDialog from './ConfirmCloseDialog';

type ChannelCreateEditFormProps = {
  handleFormClose: () => void;
  handleFormSubmit: (values: ChannelCreateEditFormValues) => void;
  dataTestId?: string;
  initialValues?: Partial<ChannelCreateEditFormValues>;
  title: string;
  conversationId?: string;
  conversationName?: string;
  isLoading?: boolean;
  isSaving: boolean;
  isOwnerAdmin: boolean;
  isEditMode?: boolean;
  ownershipInfo?: string;
};

const ChannelCreateEditForm = ({
  handleFormClose,
  handleFormSubmit,
  dataTestId,
  initialValues,
  title,
  conversationId,
  conversationName,
  isOwnerAdmin,
  isLoading = false,
  isSaving = false,
  isEditMode = false,
  ownershipInfo,
}: ChannelCreateEditFormProps) => {
  const makeTestId = useMakeTestId('ChannelCreateEditForm', dataTestId);
  const mainTestId = makeTestId('');

  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const isActionsMenuOpen = Boolean(actionsMenuAnchor);

  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const shouldRenderArchiveDialog = isEditMode && !!conversationId && !!conversationName;
  const shouldDisplayActionsMenu = isEditMode;

  const locationsAsSelectorItems = useSelector(getAdministerableLocationsAsSelectorItems);
  const staffAsSelectorItems = useSelector(getAdministerableStaffUsersAsSelectorItems);
  const contactsAsSelectorItemsForOwned = useSelector(
    getAdministerableContactUsersAsSelectorItems,
  );

  const contactsAsSelectorItems = isOwnerAdmin ? contactsAsSelectorItemsForOwned : [];

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setActionsMenuAnchor(event.currentTarget);
  const closeActionsMenu = () => setActionsMenuAnchor(null);
  const onArchiveActionClick = () => {
    setIsArchiveDialogOpen(true);
    closeActionsMenu();
  };
  const onCloseArchiveDialogClick = () => setIsArchiveDialogOpen(false);
  const onCancelConfirmDialogClick = () => setIsConfirmDialogOpen(false);

  return (
    <>
      {shouldRenderArchiveDialog && isArchiveDialogOpen && (
        <ArchiveChannelDialog
          conversationId={conversationId}
          conversationName={conversationName}
          isOpen={isArchiveDialogOpen}
          onActionSuccess={handleFormClose}
          onClose={onCloseArchiveDialogClick}
        />
      )}
      <ConfirmCloseDialog
        dataTestId={mainTestId}
        open={isConfirmDialogOpen}
        onCancelClick={onCancelConfirmDialogClick}
        onDiscardClick={handleFormClose}
      />
      <SerenityForm
        enableReinitialize
        validateOnBlur
        validateOnChange
        config={ChannelCreateEditFormConfig}
        initialValuesOverride={initialValues}
        onSubmit={handleFormSubmit}
      >
        {({ submitForm, dirty, setFieldValue }) => {
          const onDrawerCloseClick = () => {
            if (dirty || isSaving) {
              setIsConfirmDialogOpen(true);
              return;
            }

            handleFormClose();
          };

          return (
            <Drawer open onClose={onDrawerCloseClick}>
              <Drawer.Header loading={isLoading || isSaving}>
                <Box sx={headerInfoSx}>
                  <Typography noWrap data-testid={makeTestId('title')} variant="h6">
                    {title}
                  </Typography>
                  {ownershipInfo && (
                    <Typography noWrap data-testid={makeTestId('ownership-info')} variant="body2">
                      {ownershipInfo}
                    </Typography>
                  )}
                </Box>
                {shouldDisplayActionsMenu && (
                  <>
                    <Button
                      color="neutral"
                      data-testid={makeTestId('actions')}
                      disabled={isLoading}
                      endIcon={<KeyboardArrowDown sx={actionsButtonIconSx} />}
                      sx={actionsButtonSx}
                      onClick={openActionsMenu}
                    >
                      Actions
                    </Button>
                    <Menu
                      anchorEl={actionsMenuAnchor}
                      open={isActionsMenuOpen}
                      onClose={closeActionsMenu}
                    >
                      <MenuItem
                        key="archive-action"
                        data-testid={makeTestId('archive-action')}
                        onClick={onArchiveActionClick}
                      >
                        Archive
                      </MenuItem>
                    </Menu>
                    <Divider orientation="vertical" sx={headerDividerSx} />
                  </>
                )}
              </Drawer.Header>
              <Drawer.Content>
                <Form disabled={isLoading || isSaving}>
                  <Typography display="block" variant="overline">
                    general
                  </Typography>
                  <Divider sx={dividerSx} />
                  <FormikField dataTestId={makeTestId('name')} name="name" type="text" />
                  <Typography display="block" variant="overline">
                    staff & contact members
                  </Typography>
                  <Divider sx={dividerSx} />
                  {!isOwnerAdmin && (
                    <Alert severity="info" sx={alertSx}>
                      Note, only this channel's owner can manage Contacts.
                    </Alert>
                  )}
                  <ChipSelectorField
                    dataTestId={makeTestId('familyAndFriends')}
                    disabled={!isOwnerAdmin || isLoading}
                    items={contactsAsSelectorItems}
                    name="familyAndFriends"
                  />
                  <ChipSelectorField
                    dataTestId={makeTestId('staff')}
                    disabled={isLoading}
                    items={staffAsSelectorItems}
                    name="staff"
                  />
                  <Typography display="block" variant="overline">
                    filters
                  </Typography>
                  <Divider sx={dividerSx} />
                  <ChipSelectorField
                    dataTestId={makeTestId('filters')}
                    disabled={isLoading}
                    items={locationsAsSelectorItems}
                    name="locations"
                  />
                  <Typography display="block" variant="overline">
                    other
                  </Typography>
                  <Divider sx={dividerSx} />
                  <FormikField dataTestId={makeTestId('description')} name="description" />
                  <Box sx={{ pb: 2 }}>
                    <FormControlLabel
                      control={
                        <SwitchField
                          name="isOneWayChannel"
                          onChange={(event) => {
                            const isSwitchedOn = event.target.checked;
                            setFieldValue('isOneWayChannel', isSwitchedOn);
                          }}
                        />
                      }
                      label="Restricted Posting"
                    />
                    <Alert severity="info" sx={alertSx}>
                      Enable to restrict posting in this channel to authorized user groups only.
                    </Alert>
                  </Box>
                </Form>
              </Drawer.Content>
              <Drawer.Footer>
                <Button
                  data-testid={makeTestId('close')}
                  disabled={isSaving}
                  onClick={onDrawerCloseClick}
                >
                  Close
                </Button>
                <LoadingButton
                  data-testid={makeTestId('save')}
                  disabled={isLoading || !dirty}
                  loading={isSaving}
                  variant="contained"
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </Drawer.Footer>
            </Drawer>
          );
        }}
      </SerenityForm>
    </>
  );
};

const actionsButtonSx = {
  color: 'text.primary',
  mr: 1,
};

const actionsButtonIconSx = {
  color: 'action.active',
};

const alertSx = {
  mt: 2.25,
  mb: 2,
};

const dividerSx = {
  mb: 2.25,
};

const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

const headerDividerSx = {
  height: '40px',
};

export default ChannelCreateEditForm;
