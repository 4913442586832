import { Fragment, useRef, useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { LibraryAddCheck } from '@mui/icons-material';
import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  BLUR_COMMAND,
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_TAB_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import {
  slashCommandInvoke,
  useViewerBaseInformation,
  viewerSlashCommandsTransformer,
} from '@serenityapp/redux-store';
import {
  SlashCommandType,
  AbstractUserProps,
  AwaySettings,
  SlashCommandProps,
  getCommandTypeFromArgs,
  ParsedSlashCommand,
} from '@serenityapp/domain';
import { DateFn, IdFn, ZonedDateFn } from '@serenityapp/core';
import {
  AUTOCOMPLETE_SLASH_COMMAND,
  CLEAR_ATTACHMENT_COMMAND,
  INVOKE_SLASH_COMMAND_COMMAND,
  SEND_BUTTON_CLICKED_COMMAND,
} from '../customLexicalCommands';
import { $isMentionNode } from '../nodes/MentionNode';
import ConfirmCheckInDialog from './components/ConfirmCheckInDialog';
import { useCurrentUser } from '../../../../common/hooks';

const MIDDOT_SYMBOL = String.fromCharCode(183);
const SLASH = '/';

type SlashCommandPropsWithIndex = SlashCommandProps & {
  commandIndex: number;
};

type SlashCommandsPluginProps = {
  conversationId: string;
};

/**
 * Parses a slash command from the root text content in lexical state.
 *
 * This function retrieves the root text content and all text nodes from the root.
 * It then removes the slash from the root text and extracts the command name (app) and arguments.
 * If a user is mentioned in the arguments, the function replaces the user's full name with their ID.
 *
 * @returns An object representing the parsed command, including the command name (app), arguments, command type, and mentioned user (if any).
 */
const parseSlashCommand = (): ParsedSlashCommand => {
  const rootText = $getRoot().getTextContent();
  const allTextNodes = $getRoot().getAllTextNodes();

  // Remove slash command, remove any large spaces and trim the text before we parse it
  const rootTextWithoutSlash = rootText.substring(1).replace(/\s/g, ' ').trim();

  const firstSpaceIndexOrEnd = rootTextWithoutSlash.search(/\s|$/);
  const app = rootTextWithoutSlash.substring(0, firstSpaceIndexOrEnd);
  const args = rootTextWithoutSlash.substring(firstSpaceIndexOrEnd + 1);

  const mentionNode = allTextNodes.find((node) => $isMentionNode(node));

  let parsedArgs = args;
  let mentionedUser = undefined;

  if (mentionNode) {
    mentionedUser = mentionNode.getUser();
    const mentionedUserFullName = mentionNode.getMention();
    parsedArgs = args.replace(mentionedUserFullName, `@${mentionedUser.id}`);
  }

  return {
    app,
    args: parsedArgs,
    type: getCommandTypeFromArgs(parsedArgs),
    mentionedUser,
  };
};

const SlashCommandsPlugin = ({
  conversationId,
}: SlashCommandsPluginProps): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();

  const dispatch = useDispatch();

  // To handle scroll to "focused" command in the list
  const focusedCommandRef = useRef<HTMLDivElement>(null);

  const [focusedCommandIndex, setFocusedCommandIndex] = useState<number>(0);
  const [userInputSlashCommand, setUserInputSlashCommand] = useState<string>('');
  const [isSlashTriggered, setIsSlashTriggered] = useState(false);
  const [displayConfirmCheckInDialogData, setDisplayConfirmCheckInDialogData] = useState<
    | {
        checkInUser: AbstractUserProps;
        checkInUserAwaySettings: AwaySettings;
        slashCommand: ParsedSlashCommand;
      }
    | undefined
  >(undefined);

  const viewerData = useViewerBaseInformation();
  const currentUser = useCurrentUser();
  const availableSlashCommands = viewerSlashCommandsTransformer(
    viewerData.data.availableSlashCommands,
    userInputSlashCommand,
  );

  const slashCommandsCount = useMemo(
    () =>
      Object.values(availableSlashCommands).reduce(
        (count, appSlashCommands) => count + appSlashCommands.length,
        0,
      ),
    [availableSlashCommands],
  );
  const areAvailableSlashCommands = Object.keys(availableSlashCommands).length > 0;

  const availableSlashCommandsWithIndex = useMemo(() => {
    let commandIndex = -1;
    const result: Record<string, SlashCommandPropsWithIndex[]> = {};
    Object.keys(availableSlashCommands).map((appName) => {
      return (result[appName] = availableSlashCommands[appName].map((slashCommand) => {
        commandIndex++;
        return { ...slashCommand, commandIndex };
      }));
    });
    return result;
  }, [availableSlashCommands]);

  useEffect(() => {
    setFocusedCommandIndex(0);
  }, [slashCommandsCount]);

  useEffect(() => {
    if (focusedCommandRef.current) {
      focusedCommandRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focusedCommandIndex]);

  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        const root = $getRoot();
        const rootTextContent = root.getTextContent();

        if (rootTextContent.startsWith(SLASH)) {
          setIsSlashTriggered(true);
        } else {
          setIsSlashTriggered(false);
        }

        setUserInputSlashCommand(rootTextContent);
      });
    };
    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      removeUpdateListener();
    };
  }, [editor, slashCommandsCount]);

  const findFocusedCommandByIndex = useCallback(
    (focusedCommandIndex: number) => {
      for (const appName in availableSlashCommandsWithIndex) {
        const slashCommands = availableSlashCommandsWithIndex[appName];
        const focusedCommand = slashCommands.find(
          (slashCommand) => slashCommand.commandIndex === focusedCommandIndex,
        );
        if (focusedCommand) {
          return focusedCommand;
        }
      }
      return null;
    },
    [availableSlashCommandsWithIndex],
  );

  const invokeSlashCommand = useCallback(
    (slashCommand: ParsedSlashCommand) => {
      dispatch(
        slashCommandInvoke({
          onSuccess: () => null,
          onFailed: () => null,
          user: slashCommand.mentionedUser,
          slashCommandType: slashCommand.type,
          viewer: currentUser,
          variables: {
            input: {
              invocationId: IdFn.new(),
              conversationId,
              command: slashCommand.app,
              arguments: slashCommand.args,
              invokedFrom: 'Web app',
            },
          },
        }),
      );
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
      editor.dispatchCommand(CLEAR_ATTACHMENT_COMMAND, undefined);
      editor.focus();
    },
    [conversationId, currentUser, dispatch, editor],
  );

  useEffect(() => {
    const messageEntry = document.getElementById('messageEntry');
    return mergeRegister(
      editor.registerCommand(
        AUTOCOMPLETE_SLASH_COMMAND,
        (payload) => {
          const root = $getRoot();
          const rootTextContent = root.getTextContent();
          const selection = $getSelection();
          const oldTextNode = selection?.getNodes()[0];

          if (rootTextContent.includes(payload)) {
            return false;
          }

          const newTextNode = $createTextNode(`${SLASH}${payload}`);
          oldTextNode?.replace(newTextNode);

          root.selectEnd();

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null && !event.shiftKey) {
            event.preventDefault();
            if (isSlashTriggered && areAvailableSlashCommands) {
              const focusedCommand = findFocusedCommandByIndex(focusedCommandIndex);
              if (focusedCommand) {
                editor.dispatchCommand(
                  AUTOCOMPLETE_SLASH_COMMAND,
                  focusedCommand.commandCompletion,
                );
              }
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null && !event.shiftKey) {
            event.preventDefault();
            if (isSlashTriggered && !areAvailableSlashCommands) {
              editor.dispatchCommand(INVOKE_SLASH_COMMAND_COMMAND, undefined);
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_NORMAL,
      ),
      editor.registerCommand(
        KEY_ARROW_UP_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            event.preventDefault();
            setFocusedCommandIndex((focusedCommandIndex) => {
              const newId = focusedCommandIndex - 1;
              return newId < 0 ? slashCommandsCount - 1 : newId;
            });
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        KEY_ARROW_DOWN_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            setFocusedCommandIndex((focusedCommandIndex) => {
              const newId = focusedCommandIndex + 1;
              return newId > slashCommandsCount - 1 ? 0 : newId;
            });
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        KEY_TAB_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            if (isSlashTriggered && areAvailableSlashCommands) {
              event.preventDefault();

              const focusedCommand = findFocusedCommandByIndex(focusedCommandIndex);

              if (focusedCommand) {
                editor.dispatchCommand(
                  AUTOCOMPLETE_SLASH_COMMAND,
                  focusedCommand.commandCompletion,
                );
              }
            }
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        KEY_ARROW_LEFT_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            const rootTextContent = $getRoot().getTextContent();
            if (rootTextContent.endsWith(SLASH)) {
              event.preventDefault();
              editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const rootTextContent = $getRoot().getTextContent();
            const anchorOffset = selection.anchor.offset;
            if (rootTextContent.endsWith(SLASH) && anchorOffset === 0) {
              editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
            }
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (payload) => {
          const currentTarget = payload.relatedTarget as Node;
          if (isSlashTriggered) {
            if (currentTarget && !messageEntry?.contains(currentTarget)) {
              setIsSlashTriggered(false);
            }
          }

          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        INVOKE_SLASH_COMMAND_COMMAND,
        () => {
          const slashCommand = parseSlashCommand();
          const { mentionedUser, type: slashCommandType } = slashCommand;

          const now = ZonedDateFn.now();
          const isResidentAway =
            mentionedUser?.awaySettings &&
            DateFn.isBetween(now, mentionedUser.awaySettings.from, mentionedUser.awaySettings.to);

          if (
            slashCommandType === SlashCommandType.CheckIn &&
            mentionedUser?.awaySettings &&
            isResidentAway
          ) {
            setDisplayConfirmCheckInDialogData({
              checkInUser: mentionedUser,
              checkInUserAwaySettings: mentionedUser.awaySettings,
              slashCommand,
            });
            return true;
          }

          invokeSlashCommand(slashCommand);
          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        SEND_BUTTON_CLICKED_COMMAND,
        () => {
          const rootText = $getRoot().getTextContent();
          if (rootText.startsWith('/')) {
            editor.dispatchCommand(INVOKE_SLASH_COMMAND_COMMAND, undefined);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    );
  }, [
    editor,
    findFocusedCommandByIndex,
    focusedCommandIndex,
    slashCommandsCount,
    areAvailableSlashCommands,
    isSlashTriggered,
    conversationId,
    dispatch,
    invokeSlashCommand,
  ]);

  const handleCommandClick = useCallback(
    (commandCompletion: string) => {
      editor.dispatchCommand(AUTOCOMPLETE_SLASH_COMMAND, commandCompletion);
    },
    [editor],
  );

  return (
    <>
      {displayConfirmCheckInDialogData && (
        <ConfirmCheckInDialog
          awaySettings={displayConfirmCheckInDialogData.checkInUserAwaySettings}
          handleCancelClick={() => setDisplayConfirmCheckInDialogData(undefined)}
          handleConfirmClick={() => {
            invokeSlashCommand(displayConfirmCheckInDialogData.slashCommand);
            setDisplayConfirmCheckInDialogData(undefined);
          }}
          userFullName={
            displayConfirmCheckInDialogData.checkInUser.fullName ||
            displayConfirmCheckInDialogData.checkInUser.name ||
            ''
          }
        />
      )}
      {isSlashTriggered && areAvailableSlashCommands ? (
        <Paper component="div" elevation={8} sx={paperSx}>
          <List dense>
            {Object.entries(availableSlashCommandsWithIndex).map(([appName, slashCommands]) => (
              <Fragment key={appName}>
                <ListSubheader disableSticky component="div" sx={listSubheaderSx}>
                  {appName}
                </ListSubheader>
                {slashCommands.map(
                  ({
                    commandCompletion,
                    commandLabel,
                    description,
                    commandIndex,
                  }: SlashCommandPropsWithIndex) => {
                    return (
                      <ListItem
                        key={`${commandCompletion}-${commandIndex}`}
                        disableGutters
                        component="div"
                        sx={listItemSx}
                        onClick={() => handleCommandClick(commandCompletion)}
                      >
                        <ListItemButton
                          ref={
                            focusedCommandIndex === commandIndex ? focusedCommandRef : undefined
                          }
                          alignItems="flex-start"
                          selected={focusedCommandIndex === commandIndex}
                          sx={listItemButtonSx}
                        >
                          <ListItemIcon sx={listItemIconSx}>
                            <LibraryAddCheck sx={{ color: 'primary.main' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={commandLabel}
                            secondary={`${appName} ${MIDDOT_SYMBOL} ${description}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  },
                )}
              </Fragment>
            ))}
          </List>
        </Paper>
      ) : null}
    </>
  );
};

const listSubheaderSx = {
  textTransform: 'uppercase',
  color: 'text.primary',
  letterSpacing: 1,
  fontWeight: 400,
  lineHeight: '44px',
};

const listItemSx = {
  py: 0,
};

const listItemButtonSx = {
  py: 0,
};

const listItemIconSx = {
  minWidth: 20,
  mr: 1,
};

const paperSx = {
  maxHeight: 240,
  maxWidth: 500,
  overflowY: 'auto',
  position: 'absolute',
  bottom: 98,
  left: 0,
  zIndex: 1000,
};

export default SlashCommandsPlugin;
