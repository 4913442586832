import { useState } from 'react';
import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import { SelectorItemProps } from '@serenityapp/components-react-common';
import {
  snackAdd,
  useCheckInPolicies,
  useCheckInPolicyEditMutation,
} from '@serenityapp/redux-store';

import { useCurrentUser } from '../../../common/hooks';
import CheckInPolicyCreateEditForm from './CheckInPolicyCreateEditForm';
import { checkInPolicyTransformer, getCheckInSchedules } from './utils';
import { App, ServiceLevel } from '@serenityapp/core';
import { KeyValuePair } from '@serenityapp/domain';

type CheckInPolicyEditDrawerContentProps = {
  policyId: string;
  returnToCheckInAppDetail: () => void;
  onCheckInPolicyFormStateChanged: (isDirty: boolean) => void;
  onConfirmDialogOpen: (isOpen: boolean) => void;
  openInfoDialog: () => void;
};

const CheckInPolicyEditDrawerContent = ({
  policyId,
  returnToCheckInAppDetail,
  onCheckInPolicyFormStateChanged,
  onConfirmDialogOpen,
  openInfoDialog,
}: CheckInPolicyEditDrawerContentProps) => {
  const dispatch = useDispatch();

  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId;

  const [folderValidationFailed, setFolderValidationFailed] = useState(false);

  const { isFetching: isSaving, doMutation } = useCheckInPolicyEditMutation();

  const initialErrors = folderValidationFailed
    ? { reportFolderId: 'Either the folder is missing or we do not have access to it.' }
    : {};

  const { data: checkInPoliciesData, isLoading: isLoadingCheckInPolicies } = useCheckInPolicies();

  const checkInPolicy = checkInPolicyTransformer(
    checkInPoliciesData,
    isLoadingCheckInPolicies,
    policyId,
  );

  const timezone = checkInPolicy?.schedules?.[0]?.start?.timeZone;

  const initialValues = {
    name: checkInPolicy?.name,
    timeSliderValue: checkInPolicy?.schedulesTime,
    checkInFrequency: (checkInPolicy?.schedulesTime.length || 0) - 1,
    serviceLevels: checkInPolicy?.serviceLevels.filter(
      (serviceLevel) =>
        serviceLevel.id !== ServiceLevel.NONE && serviceLevel.id !== ServiceLevel.UNKNOWN,
    ),
    isApprovalRequired: (checkInPolicy?.approvers?.length || 0) > 0,
    approvers: checkInPolicy?.approvers,
    viewers: checkInPolicy?.viewers,
    managers: checkInPolicy?.managers,
    includeOptedOut: checkInPolicy?.includeOptedOut ?? false,
    useCloudStorage: !!checkInPolicy?.cloudStorageName,
    cloudStorageName:
      checkInPolicy?.cloudStorageName !== 'S3' ? checkInPolicy?.cloudStorageName : undefined,
    reportFolderId: checkInPolicy?.reportFolderId,
    allowAutoMove: checkInPolicy?.allowAutoMove ?? false,
    checkInPolicyTimeZone: checkInPolicy?.checkInPolicyTimeZone,
  };

  const handleSuccess = () => {
    onCheckInPolicyFormStateChanged(false);
    dispatch(snackAdd({ message: 'Check-In policy successfully edited.', type: 'success' }));
    returnToCheckInAppDetail();
    openInfoDialog();
  };

  const handleFailed = () => {
    dispatch(snackAdd({ message: 'Error editing check-in policy.', type: 'error' }));
  };

  const handleSubmit = (values: FormikValues) => {
    if (!organizationId) return;
    setFolderValidationFailed(false);

    let schedules;

    if (
      initialValues.name !== values.name ||
      !R.equals(initialValues.timeSliderValue, values.timeSliderValue)
    ) {
      schedules = getCheckInSchedules(values.name, values.timeSliderValue, timezone);
    } else {
      schedules = checkInPolicy?.schedules;
    }

    const cloudStorageSettings: KeyValuePair[] = [];

    if (values.useCloudStorage) {
      if (values.cloudStorageName) {
        cloudStorageSettings.push({
          key: 'cloudStorageName',
          stringValue: values.cloudStorageName,
          type: 'string',
        });

        if (values.cloudStorageName === App.Names.Box && values.reportFolderId) {
          cloudStorageSettings.push({
            key: 'reportFolderId',
            stringValue: values.reportFolderId,
            type: 'string',
          });
        }
      } else {
        cloudStorageSettings.push({
          key: 'cloudStorageName',
          stringValue: 'S3',
          type: 'string',
        });
      }
    }

    doMutation(
      {
        input: {
          organization: {
            id: organizationId,
          },
          checkInPolicy: {
            id: policyId,
            name: values.name,
            schedules,
            includeOptedOut: values.includeOptedOut,
            viewers: values.viewers.map((viewer: SelectorItemProps) => viewer.id),
            serviceLevels: values.serviceLevels.map(
              (serviceLevel: SelectorItemProps) => serviceLevel.id,
            ),
            approvers:
              values.approvers.length === 0
                ? []
                : values.approvers.map((approver: SelectorItemProps) => approver.id),
            managers: values.managers.map((manager: SelectorItemProps) => manager.id),
            cloudStorageSettings,
            allowAutoMove: values.allowAutoMove,
          },
        },
      },
      handleSuccess,
      handleFailed,
    );
  };

  return (
    <CheckInPolicyCreateEditForm
      handleSubmit={handleSubmit}
      initialErrors={initialErrors}
      initialValues={initialValues}
      isLoading={isLoadingCheckInPolicies}
      isSaving={isSaving}
      returnToCheckInAppDetail={returnToCheckInAppDetail}
      title="Edit Check-In policy"
      onCheckInPolicyFormStateChanged={onCheckInPolicyFormStateChanged}
      onConfirmDialogOpen={onConfirmDialogOpen}
    />
  );
};

export default CheckInPolicyEditDrawerContent;
