import * as platform from 'platform';
import { IdFn } from '@serenityapp/core';

export function deviceAttributes() {
  const browserType = platform.name ?? 'unknown';
  const browserVersion = platform.version ?? 'unknown';
  const operatingSystem = platform.os?.toString() ?? 'unknown';
  return {
    idKey: IdFn.fromString(browserType + browserVersion + operatingSystem),
    browserType,
    browserVersion,
    operatingSystem,
  };
}
