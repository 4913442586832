import { organizationDevicesQuery, OrganizationDevicesResult } from '@serenityapp/api-graphql';
import { PageInfo } from '@serenityapp/core';
import { Schema } from '@serenityapp/domain';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';
import { DEFAULT_DEVICES_PAGE_SIZE } from './utils';

const useDevices = (organizationId: string) => {
  const [lastPageInfo, setLastPageInfo] = useState<PageInfo>({
    endCursor: '',
    hasNextPage: true,
    hasPreviousPage: false,
    startCursor: '',
  });

  const [isFetching, setFetching] = useState(true);

  const [result] = useQuery<OrganizationDevicesResult>({
    query: organizationDevicesQuery,
    variables: {
      getInput: { id: organizationId },
      pageInput: { first: DEFAULT_DEVICES_PAGE_SIZE, after: lastPageInfo.endCursor },
    },
    requestPolicy: 'cache-and-network',
  });

  const { edges, pageInfo } = result?.data?.result?.devices || {};

  useEffect(() => {
    if (pageInfo) {
      if (pageInfo.endCursor !== lastPageInfo.endCursor) {
        setLastPageInfo(pageInfo);
      }
      if (!pageInfo.hasNextPage) {
        setFetching(false);
      }
    }
  }, [lastPageInfo.endCursor, pageInfo]);

  const devices: Schema.Device.Item[] =
    edges?.map((edge: Schema.Organization.Devices.Edge) => edge.device) || [];

  return { devices, isFetching };
};

export default useDevices;
