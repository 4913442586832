import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import {
  snackAdd,
  locationArchive,
  getIsLocationArchiving,
  RootState,
} from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

type ArchiveFilterDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  filterName: string;
  filterId: string;
  onActionSuccess?: () => void;
};

const ArchiveFilterDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  filterName,
  filterId,
  onActionSuccess,
}: ArchiveFilterDialogProps) => {
  const makeTestId = useMakeTestId('ArchiveFilterDialog', dataTestId);
  const archiveFilterDialog = makeTestId('');

  const isArchiving = useSelector((state: RootState) => getIsLocationArchiving(state, filterId));
  const dispatch = useDispatch();

  const handleConfirmClick = () => {
    dispatch(
      locationArchive({
        locationId: filterId,
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error archiving filter', type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'Filter successfully archived', type: 'success' }));
          onClose();
          onActionSuccess?.();
        },
      }),
    );
  };

  return (
    <Dialog
      fullWidth
      data-testid={archiveFilterDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Archive filter</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to Archive {filterName}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>
            Archiving will remove {filterName} from the filters list.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isArchiving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isArchiving}
          variant="contained"
          onClick={handleConfirmClick}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ArchiveFilterDialog;
