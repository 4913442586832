import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';

import { LocationCreateEditFormValues } from '@serenityapp/components-react-common';
import { locationAdd, getIsLocationAdding } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';

import FilterCreateEditForm from './components/FilterCreateEditForm';
import { IdFn } from '@serenityapp/core';

const FilterCreateDrawer = () => {
  const makeTestId = useMakeTestId('FilterCreateDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isFilterAdding = useSelector(getIsLocationAdding);

  const goBack = () => navigate('..');

  const handleFormSubmit = (values: LocationCreateEditFormValues) => {
    const locationId = IdFn.new();
    const selectedChannels = [
      ...(values.residentChannels || []),
      ...(values.generalChannels || []),
    ];

    const locationToAdd = {
      id: locationId,
      name: values.name?.trim(),
      description: values.description?.trim(),
      filterPositionPriority: values.filterPositionPriority || undefined,
      conversations: R.pluck('id', selectedChannels),
    };

    dispatch(
      locationAdd({
        location: locationToAdd,
        onFailed: goBack,
        onSuccess: goBack,
      }),
    );
  };

  return (
    <FilterCreateEditForm
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      isSaving={isFilterAdding}
      title="Create new general filter"
    />
  );
};

export default FilterCreateDrawer;
