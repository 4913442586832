import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationDetail, locationDetail, RootState } from '@serenityapp/redux-store';

export default function useLocationDetail(locationId: string) {
  const dispatch = useDispatch();

  const locationDetailData = useSelector((state: RootState) =>
    getLocationDetail(state, locationId),
  );

  useEffect(() => {
    dispatch(locationDetail({ locationId }));
  }, [dispatch, locationId]);

  return locationDetailData;
}
